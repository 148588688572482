import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { maxLength, required } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  passwordForbiddenKeywords = _useAppConfig.validations.passwordForbiddenKeywords;
var COMMON_FORBIDDEN_KEYWORDS = ["password", "pwd", "123456", "123456789", "qwerty", "abcdefgh"];
export function usePasswordNewValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        user = _ref.user;
      return {
        required: required,
        maxLength: maxLength(150),
        minLength: function minLength(value) {
          return (value === null || value === void 0 ? void 0 : value.length) >= 8;
        },
        containsUppercase: function containsUppercase(value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function containsLowercase(value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function containsNumber(value) {
          return /[0-9]/.test(value);
        },
        doesNotContainForbiddenWords: function doesNotContainForbiddenWords(value) {
          var forbiddenKeywords = [].concat(COMMON_FORBIDDEN_KEYWORDS, _toConsumableArray(passwordForbiddenKeywords));
          var forbiddenKeywordRules = [];
          forbiddenKeywords.forEach(function (keyword) {
            if (value !== null && value !== void 0 && value.toLowerCase().includes(keyword)) {
              forbiddenKeywordRules.push(keyword);
            }
          });
          return !forbiddenKeywordRules.length;
        },
        doesNotContainRepetitiveTerms: function doesNotContainRepetitiveTerms(value) {
          return !/\b([a-zA-Z0-9]+)\1\b/.test(value);
        },
        doesNotContainPersonalData: function doesNotContainPersonalData(value) {
          var _user$firstName, _user$firstName2, _user$lastName, _user$lastName2, _user$emailToCheck, _user$emailToCheck$sp, _user$emailToCheck$sp2;
          if (!value || !user) {
            return true;
          }
          var innerValue = value.toLowerCase();
          var emailToCheck = user ? "email" : "loginEmail";
          if ((_user$firstName = user.firstName) !== null && _user$firstName !== void 0 && _user$firstName.toLowerCase() && innerValue.includes((_user$firstName2 = user.firstName) === null || _user$firstName2 === void 0 ? void 0 : _user$firstName2.toLowerCase())) {
            return false;
          }
          if ((_user$lastName = user.lastName) !== null && _user$lastName !== void 0 && _user$lastName.toLowerCase() && innerValue.includes((_user$lastName2 = user.lastName) === null || _user$lastName2 === void 0 ? void 0 : _user$lastName2.toLowerCase())) {
            return false;
          }
          var firstPartOfEmail = (_user$emailToCheck = user[emailToCheck]) === null || _user$emailToCheck === void 0 ? void 0 : (_user$emailToCheck$sp = _user$emailToCheck.split("@")) === null || _user$emailToCheck$sp === void 0 ? void 0 : (_user$emailToCheck$sp2 = _user$emailToCheck$sp[0]) === null || _user$emailToCheck$sp2 === void 0 ? void 0 : _user$emailToCheck$sp2.toLowerCase();
          if (!firstPartOfEmail) {
            return true;
          }
          return !innerValue.toLowerCase().includes(firstPartOfEmail);
        }
      };
    },
    errors: function errors() {
      return {
        required: t("validations.Password is required"),
        maxLength: t("validations.Password can contain a maximum of x characters", {
          length: 150
        }),
        minLength: t("validations.Password can contain a minimum of x characters", {
          length: 8
        }),
        containsUppercase: t("validations.Password has to contain uppercase"),
        containsLowercase: t("validations.Password has to contain lowercase"),
        containsNumber: t("validations.Password has to contain number"),
        doesNotContainForbiddenWords: t("validations.Password contains a forbidden word"),
        doesNotContainRepetitiveTerms: t("validations.Password cannot contain repetitive characters"),
        doesNotContainPersonalData: t("validations.Password cannot contain personal data")
      };
    }
  };
}