import { field } from '@nsf/core/GraphQL.js'
import cartShippingAddressesSelectedShippingMethod from './cartShippingAddressesSelectedShippingMethod.js'
import cartShippingAddressesAppliedShippingRestrictions from './cartShippingAddressesAppliedShippingRestrictions.js'
import cartShippingAddressesAvailableShippingMethods from './cartShippingAddressesAvailableShippingMethods.js'

export default field('shipping_addresses', [
  cartShippingAddressesAvailableShippingMethods,
  cartShippingAddressesAppliedShippingRestrictions,
  cartShippingAddressesSelectedShippingMethod,
  field('is_common_map_enabled'),
  field('common_map_icon'),
])
