export const iconRegistry = {
  // arrows.svg
  'arrow-back': 'arrows',
  'arrow-down': 'arrows',
  'arrow-left': 'arrows',
  'arrow-right': 'arrows',
  'chevron-down': 'arrows',
  'chevron-left': 'arrows',
  'chevron-right': 'arrows',
  'chevron-up': 'arrows',
  'download': 'arrows',
  'log-out': 'arrows',
  'repeat': 'arrows',
  'share': 'arrows',

  // communication.svg
  'email': 'communication',
  'phone': 'communication',
  'sms': 'communication',
  'whatsapp': 'communication',

  // content.svg
  'black-friday': 'content',
  'child': 'content',
  'dog': 'content',
  'feel': 'content',
  'pregnant': 'content',
  'tip': 'content',

  // deliveryAndLocation.svg
  'box': 'deliveryAndLocation',
  'box-return': 'deliveryAndLocation',
  'distance-arrow': 'deliveryAndLocation',
  'free-delivery': 'deliveryAndLocation',
  'pick-up-place': 'deliveryAndLocation',
  'truck': 'deliveryAndLocation',
  'truck-fast': 'deliveryAndLocation',
  'truck-moving': 'deliveryAndLocation',

  // eshop.svg
  'basket-plus': 'eshop',
  'basket-shopping': 'eshop',
  'basket-simple': 'eshop',
  'chart-line-down': 'eshop',
  'filter': 'eshop',
  'sort': 'eshop',

  // essentials.svg
  'alert': 'essentials',
  'asterisk': 'essentials',
  'bell': 'essentials',
  'bell-plus': 'essentials',
  'burger-menu': 'essentials',
  'check': 'essentials',
  'circle-check': 'essentials',
  'circle-exclamation': 'essentials',
  'circle-minus': 'essentials',
  'circle-plus': 'essentials',
  'circle-xmark': 'essentials',
  'close': 'essentials',
  'copy': 'essentials',
  'eye': 'essentials',
  'eye-slash': 'essentials',
  'grid': 'essentials',
  'heart-cracked': 'essentials',
  'heart-empty': 'essentials',
  'heart-filled': 'essentials',
  'help': 'essentials',
  'info': 'essentials',
  'key': 'essentials',
  'lock-clock': 'essentials',
  'map': 'essentials',
  'minus': 'essentials',
  'plus': 'essentials',
  'question': 'essentials',
  'recycle': 'essentials',
  'search': 'essentials',
  'separator': 'essentials',
  'square-list': 'essentials',
  'trash': 'essentials',
  'user': 'essentials',
  'user-settings': 'essentials',
  'user-v2': 'essentials',

  // icons.svg
  'check-circle-outline': 'icons',
  'exclamation': 'icons',
  'filter-list': 'icons',
  'swap-vert': 'icons',

  // measurementsAndTime.svg
  'calendar-check': 'measurementsAndTime',
  'calendar-lines': 'measurementsAndTime',
  'clock': 'measurementsAndTime',
  'clock-fast': 'measurementsAndTime',
  'ruler-horizontal': 'measurementsAndTime',
  'scale': 'measurementsAndTime',

  // paymentAndDiscount.svg
  'bank': 'paymentAndDiscount',
  'barcode-read': 'paymentAndDiscount',
  'bundle': 'paymentAndDiscount',
  'coupon': 'paymentAndDiscount',
  'discount': 'paymentAndDiscount',
  'gift': 'paymentAndDiscount',
  'gift-card': 'paymentAndDiscount',
  'payment-card': 'paymentAndDiscount',
  'payment-repeat': 'paymentAndDiscount',
  'wallet': 'paymentAndDiscount',

  // pharmacyBrand.svg
  'loyalty-card': 'pharmacyBrand',
  'loyalty-card-rotated': 'pharmacyBrand',
  'pharmacy-building': 'pharmacyBrand',
  'pharmacy-cross': 'pharmacyBrand',
  'pharmacy-sign': 'pharmacyBrand',

  // ... map all other icons to their files
} as const

export type IconId = keyof typeof iconRegistry
