import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { getCategory } from '../utils/product.js'

const {
  public: {
    appUrl,
  },
} = useRuntimeConfig()

export const mapUser = (user) => (user.firstName
  ? {
      id: user.clientId,
      firstname: user.firstName,
      surname: user.lastName,
      email: user.email,
      phone: user.phoneCode ? user.phoneCode + user.phoneNumber : user.phoneNumber,
      birthDate: user.birthDate,
      gender: user.sex,
    }
  : user)

export const mapProduct = (product) => {
  const discountedAmount = parseFloat(
    (product.regularPrice - (product.finalPrice ?? product.specialPrice)).toFixed(2),
  )
  const specialPrice = discountedAmount === 0 ? null : product.specialPrice ?? product.regularPrice - discountedAmount

  return {
    id: product.id,
    name: product.name,
    fId: product.sku,
    regularPrice: product.regularPrice ?? null,
    specialPrice,
    finalPrice: product.finalPrice ?? null,
    finalPriceExclVat: product.finalPriceExclVat ?? null,
    regularPriceExclVat: product.regularPriceExclVat,
    brand: product.drmaxBrand ?? null,
    category: getCategory(product),
    mainCategory: product.drmaxMainCategoryName || null,
    tags: product.tags ?? [],
    availablePimStatus: product.drmaxPimStatus ?? null,
    sku: product.sku,
    abcd: product.drmaxAbcd ?? null,
    url: `${appUrl}/${product.urlKey}`,
    ...(product.content && {
      content: {
        ...product.content,
      },
    }),
    manufacturer: product.manufacturerName
      ?? product.drmaxManufacturer
      ?? product.drmaxManufacter
      ?? product.manufacturer
      ?? '',
    discountedAmount: discountedAmount > 0 ? discountedAmount : null,
    groupDiscountActive: product.groupPrice?.discount > 0,
    groupDiscountValue: product.finalPriceLogged
      ? parseFloat((product.finalPrice - product.finalPriceLogged).toFixed(2))
      : 0,
  }
}
