import { maxLength } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  customerNotesAllowedCharacters = _useAppConfig.validations.customerNotesAllowedCharacters;
export function useCustomerNotesValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      return {
        maxLength: maxLength(120),
        allowedCharacters: customerNotesAllowedCharacters
      };
    },
    errors: function errors() {
      return {
        maxLength: t("validations.Customer notes can contain a maximum of x characters", {
          length: 120
        }),
        allowedCharacters: t("validations.Customer notes contain disallowed characters")
      };
    }
  };
}