/* eslint-disable import/prefer-default-export */
import { getProductsByIds } from '@nsf/catalog/repositories/ProductRepository.js'
import { setState } from '@nsf/core/Store.js'
import {
  addPriceAlert,
  addStockAlert,
  getAlertsForUserEmail,
  removeAllPriceAlerts,
  removeAllStockAlerts,
  removePriceAlert,
  removePriceAlertHash,
  removeStockAlert,
  removeStockAlertHash,
} from '@nsf/layer-my-account/app/repositories/AlertRepository.ts'

import {
  PRICE_ALERT, STOCK_ALERT, isPriceAlert, isStockAlert,
} from '@nsf/layer-my-account/app/utils/alerts.ts'

// FOR PINIA:
// export const useAlertsStore = defineStore('alerts', {
// change state to this, replace actions declarations by the commented ones
// remove namespaced: true, remove store declaration in Store.js

export default {
  namespaced: true,

  state: () => ({
    isAlertsInitialized: false,
    stockAlerts: [],
    priceAlerts: [],
    isErrorStockAlerts: false,
    isErrorPriceAlerts: false,
  }),

  mutations: {
    setState,
  },

  actions: {
    // async addPriceAlert(email: string, product: object, name: string, price: number) {
    async addPriceAlert({ state, commit }, {
      email, product, name, price,
    }: {
      email: string, product: object, name: string, price: number}) {
      // state.isAlertsInitialized = false
      commit('setState', { isAlertsInitialized: false })

      const { ok, errors } = await addPriceAlert(email, product.id, name, price)
      if (ok) {
        const newAlert = {
          ...product,
          threshold: price,
          type: PRICE_ALERT,
        }
        // state.priceAlerts.push(newAlert)
        commit('setState', { priceAlerts: [...state.priceAlerts, newAlert] })
      }

      // state.isAlertsInitialized = true
      commit('setState', { isAlertsInitialized: true })

      return { ok, errors }
    },

    // async addStockAlert({ state }, email: string, product: object, name: string) {
    async addStockAlert({ state, commit }, {
      email, product, name,
    }: {
      email: string, product: object, name: string}) {
      // state.isAlertsInitialized = false
      commit('setState', { isAlertsInitialized: false })

      const { ok, errors } = await addStockAlert(email, product.id, name)
      if (ok) {
        const newAlert = {
          ...product,
          type: STOCK_ALERT,
        }
        // state.stockAlerts.push(newAlert)
        commit('setState', { stockAlerts: [...state.stockAlerts, newAlert] })
      }
      // state.isAlertsInitialized = true
      commit('setState', { isAlertsInitialized: true })

      return {
        ok,
        errors,
      }
    },

    // async loadAlerts({ state }, email: string) {
    async loadAlerts({ commit }, { email }: {email: string}) {
      // state.isAlertsInitialized = false
      commit('setState', { isAlertsInitialized: false })

      const {
        isErrorPriceAlerts, isErrorStockAlerts, stockAlerts, priceAlerts,
      } = await getAlertsForUserEmail(email)

      // state.isErrorPriceAlerts = isErrorPriceAlerts
      // state.isErrorStockAlerts = isErrorStockAlerts
      commit('setState', { isErrorPriceAlerts, isErrorStockAlerts })

      if (stockAlerts.length === 0 && priceAlerts.length === 0) {
        // state.isAlertsInitialized = true
        commit('setState', { isAlertsInitialized: true })
        return
      }

      const stockAlertsIds = stockAlerts.map((item) => item.id)
      const priceAlertsIds = priceAlerts.map((item) => item.id)

      const [res1, res2] = await Promise.all([
        getProductsByIds(stockAlertsIds, { size: stockAlertsIds.length }),
        getProductsByIds(priceAlertsIds, { size: priceAlertsIds.length }),
      ])

      // state.stockAlerts = res1.products.map((item) => ({
      //   ...item,
      //   type: STOCK_ALERT,
      // }))

      commit('setState', {
        stockAlerts: res1.products.map((item) => ({
          ...item,
          type: STOCK_ALERT,
        })),
      })

      // state.priceAlerts = res2.products.map((item) => ({
      //   ...item,
      //   type: PRICE_ALERT,
      //   thresold: priceAlerts.find((priceAlert) => priceAlert.id === item.id)?.threshold,
      // }))

      commit('setState', {
        priceAlerts: res2.products.map((item) => ({
          ...item,
          type: PRICE_ALERT,
          thresold: priceAlerts.find((priceAlert) => priceAlert.id === item.id)?.threshold,
        })),
      })

      // state.isAlertsInitialized = true
      commit('setState', { isAlertsInitialized: true })
    },

    // async removeAllAlerts({ state, dispatch }, type: PRICE_ALERT | STOCK_ALERT | 'all', email: string) {
    async removeAllAlerts({ dispatch, commit }, { type, email }: {type: typeof PRICE_ALERT | typeof STOCK_ALERT | 'all', email: string}) {
      // state.isAlertsInitialized = false
      commit('setState', { isAlertsInitialized: false })

      let response
      if (type === 'all') {
        response = await Promise.all([
          removeAllPriceAlerts(email),
          removeAllStockAlerts(email),
        ])

        // Clear all alerts, just if response ok
        if (response[0].ok) {
          dispatch('removeAllPriceAlertsFromStore')
        }
        if (response[1].ok) {
          dispatch('removeAllStockAlertsFromStore')
        }
      } else if (isPriceAlert(type)) {
        response = await removeAllPriceAlerts(email)
        if (response.ok) {
          dispatch('removeAllPriceAlertsFromStore')
        }
      } else if (isStockAlert(type)) {
        response = await removeAllStockAlerts(email)
        if (response.ok) {
          dispatch('removeAllStockAlertsFromStore')
        }
      }

      // state.isAlertsInitialized = true
      commit('setState', { isAlertsInitialized: true })

      return response
    },

    removeAllPriceAlertsFromStore({ commit }) {
      // state.priceAlerts = []
      commit('setState', { priceAlerts: [] })
    },

    removeAllStockAlertsFromStore({ commit }) {
      // state.stockAlerts = []
      commit('setState', { stockAlerts: [] })
    },

    // async removePriceAlert({ state, dispatch }, productId: string, email: string) {
    async removePriceAlert({ dispatch, commit }, { productId, email }: {productId: string, email: string}) {
      // state.isAlertsInitialized = false
      commit('setState', { isAlertsInitialized: false })

      const { ok, errors } = await removePriceAlert(email, productId)
      if (ok) {
        dispatch('removePriceAlertFromStore', { productId })
      }

      // state.isAlertsInitialized = true
      commit('setState', { isAlertsInitialized: true })
      return { ok, errors }
    },

    // removePriceAlertFromStore({ state }, productId: string) {
    removePriceAlertFromStore({ state, commit }, { productId }: {productId: string}) {
      // state.priceAlerts = state.priceAlerts.filter((item) => item.id !== productId)
      commit('setState', { priceAlerts: state.priceAlerts.filter((item) => item.id !== productId) })
    },

    // async removePriceAlertHash({ state, dispatch }, hash: string, productId: string) {
    async removePriceAlertHash({ dispatch, commit }, { hash, productId }:
       {hash: string, productId: string}) {
      // state.isAlertsInitialized = false
      commit('setState', { isAlertsInitialized: false })
      const { ok } = await removePriceAlertHash(hash)
      if (ok) {
        dispatch('removePriceAlertFromStore', { productId })
      }
      // state.isAlertsInitialized = true
      commit('setState', { isAlertsInitialized: true })

      return { ok }
    },

    // async removeStockAlert({ state, dispatch }, email: string, productId: string) {
    async removeStockAlert({ dispatch }, { email, productId }:
       {email: string, productId: string}) {
      const { ok } = await removeStockAlert(email, productId)
      if (ok) {
        dispatch('removeStockAlertFromStore', { productId })
      }

      return { ok }
    },

    // removeStockAlertFromStore({ state }, productId: string) {
    removeStockAlertFromStore({ state, commit }, { productId }: {
      productId: string
    }) {
      // state.stockAlerts = state.stockAlerts.filter((item) => item.id !== productId)
      commit('setState', { stockAlerts: state.stockAlerts.filter((item) => item.id !== productId) })
    },

    // async removeStockAlertHash({ state, dispatch }, hash: string, productId: string) {
    async removeStockAlertHash({ dispatch, commit }, { hash, productId }:
      {hash: string, productId: string}) {
      // state.isAlertsInitialized = false
      commit('setState', { isAlertsInitialized: false })
      const { ok } = await removeStockAlertHash(hash)
      if (ok) {
        dispatch('removeStockAlertFromStore', { productId })
      }
      // state.isAlertsInitialized = true
      commit('setState', { isAlertsInitialized: true })

      return { ok }
    },
  },
}
// })
