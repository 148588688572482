import { useAppConfig as $useAppConfig } from "@nsf/use/composables/useAppConfig.js";
import { maxLength, required } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  _useAppConfig$validat = _useAppConfig.validations,
  streetMaxLength = _useAppConfig$validat.streetMaxLength,
  streetAllowedCharacters = _useAppConfig$validat.streetAllowedCharacters,
  streetValid = _useAppConfig$validat.streetValid;
var _$useAppConfig = $useAppConfig(),
  streetNumberEnabled = _$useAppConfig.rootConfig.global.streetNumberEnabled;
export function useStreetValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      return {
        required: required,
        maxLength: maxLength(streetMaxLength),
        valid: function valid(value) {
          return streetNumberEnabled ? true : streetValid(value);
        },
        allowedCharacters: streetAllowedCharacters
      };
    },
    errors: function errors() {
      return {
        required: t("validations.Street is required"),
        maxLength: t("validations.Street can contain a maximum of x characters", {
          length: streetMaxLength
        }),
        valid: t("validations.Street has to contain a name and a number"),
        allowedCharacters: t("validations.Street contains disallowed characters")
      };
    }
  };
}