import { defineNuxtConfig } from '@nsf/use/composables/defineNuxtConfig.js'

export default defineNuxtConfig({
  runtimeConfig: {
    public: {
      catalogServiceFiltersEnabled: false,
      catalogServiceUrl: 'https://catalog-service.drmax.zone/v1',
      reviewServiceEnabled: false,
      reviewServiceUrl: '',
    },
  },
})
