import type { Tag } from '#build/layers/tags/types/Tag.ts'

export const getSortedTagsByName = (tags: Tag[]) => {
  if (!Array.isArray(tags)) {
    return []
  }
  return tags.sort((a, b) => a.name.localeCompare(b.name))
}

export const getVisibleTags = (tags: Tag[]) => {
  if (!Array.isArray(tags)) {
    return []
  }
  return tags
}

export const getSortedTagsByWeight = (tags: Tag[]) => {
  if (!Array.isArray(tags)) {
    return []
  }
  return tags.sort((a, b) => b.weight - a.weight)
}
