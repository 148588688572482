import { defuFn } from '/app/node_modules/.pnpm/defu@6.1.4/node_modules/defu/dist/defu.mjs'
import cfg0 from "/app/layers/address-validation/layers/pl-drmax/app.config.ts"
import cfg1 from "/app/layers/adults-only/layers/pl-drmax/app.config.ts"
import cfg2 from "/app/layers/clubs/layers/pl-drmax/app.config.ts"
import cfg3 from "/app/layers/convert/layers/pl-drmax/app.config.ts"
import cfg4 from "/app/layers/csp/layers/pl-drmax/app.config.ts"
import cfg5 from "/app/layers/multi-app/app.config.ts"
import cfg6 from "/app/layers/multi-app/layers/pl-drmax/app.config.ts"
import cfg7 from "/app/layers/my-account/layers/pl-drmax/app.config.ts"
import cfg8 from "/app/layers/new-relic/app.config.ts"
import cfg9 from "/app/layers/product-relationship-loader-persoo/layers/pl-drmax/app.config.ts"
import cfg10 from "/app/layers/product-relationship-loader-placement/layers/pl-drmax/app.config.ts"
import cfg11 from "/app/layers/speed-curve/app.config.ts"
import cfg12 from "/app/layers/speed-curve/layers/pl-drmax/app.config.ts"
import cfg13 from "/app/layers/subscription/layers/pl-drmax/app.config.ts"
import cfg14 from "/app/layers/sukl-widget/layers/pl-drmax/app.config.ts"
import cfg15 from "/app/layers/validations/layers/pl-drmax/app.config.ts"
const inlineAppConfig = {}
export default /* #__PURE__ */ defuFn(cfg0,cfg1,cfg2,cfg3,cfg4,cfg5,cfg6,cfg7,cfg8,cfg9,cfg10,cfg11,cfg12,cfg13,cfg14,cfg15, inlineAppConfig)