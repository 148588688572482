import { render, staticRenderFns } from "./BackButton.vue?vue&type=template&id=6d495f69&functional=true"
import script from "./BackButton.vue?vue&type=script&lang=js"
export * from "./BackButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__lodash_ipt2tysyre5rkleisc4aol4vli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports