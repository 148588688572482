/* eslint-disable no-shadow */
import { getNuxtServerInitActions } from '@nsf/core/Store.js'
import adultsOnlyStore from '@nsf/layer-adults-only/app/store/adults-only.ts'
import myAccountAlertsStore from '@nsf/layer-my-account/app/store/alerts.ts'
import myAccountFavoriteMethodsStore from '@nsf/layer-my-account/app/store/favoriteMethods.js'
import myAccountFavoritesStore from '@nsf/layer-my-account/app/store/favorites.js'
import myAccountStore from '@nsf/layer-my-account/app/store/index.js'
import myAccountLoginStore from '@nsf/layer-my-account/app/store/login.js'
import myAccountUserStore from '@nsf/layer-my-account/app/store/user.js'
import productRelationshipLoaderPlacementStore from '@nsf/layer-product-relationship-loader-placement/app/store/product-relationship-loader-placement.ts'
import suklWidgetStore from '@nsf/layer-sukl-widget/app/store/sukl-widget.ts'

export const state = () => ({
  shade: false,
  modalShade: false,
  compact: false,
  noHeader: false,
  noFooter: false,
})

export const actions = {
  async nuxtServerInit(store, context) {
    store.commit('setCompact', !!context.req.headers['x-drmax-mobile-os'])
    await Promise.all([
      store.dispatch('taxrules/fetch'),
      ...getNuxtServerInitActions().map((action) => action(store, context)),
    ])
  },
}

export const mutations = {
  showShade(state) {
    state.shade = true
  },

  hideShade(state) {
    state.shade = false
  },

  showModalShade(state) {
    state.modalShade = true
  },

  hideModalShade(state) {
    state.modalShade = false
  },

  setCompact(state, value) {
    state.compact = value
  },

  setNoHeader(state, value) {
    state.noHeader = value
  },

  setNoFooter(state, value) {
    state.noFooter = value
  },
}

export const getters = {
  shade: (state) => state.shade,
  modalShade: (state) => state.modalShade,
  compact: (state) => state.compact,
}

export const modules = {
  'adults-only': adultsOnlyStore,
  'sukl-widget': suklWidgetStore,
  'product-relationship-loader-placement': productRelationshipLoaderPlacementStore,
  'my-account': myAccountStore,
  'my-account/favorites': myAccountFavoritesStore,
  'my-account/login': myAccountLoginStore,
  'my-account/user': myAccountUserStore,
  'my-account/favoriteMethods': myAccountFavoriteMethodsStore,
  'my-account/alerts': myAccountAlertsStore,
}
