function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { buildBabyClub } from "@nsf/layer-my-account/utils/babyclub.js";
import { useAppConfig as $useAppConfig } from "@nsf/use/composables/useAppConfig.js";
var _useAppConfig = useAppConfig(),
  _useAppConfig$myAccou = _useAppConfig.myAccount.features,
  agreementsSmsChannel = _useAppConfig$myAccou.agreementsSmsChannel,
  myAccountSorpCardCodeToOtpModeEnabled = _useAppConfig$myAccou.myAccountSorpCardCodeToOtpModeEnabled,
  myAccountSorpFromCustomerServiceEnabled = _useAppConfig$myAccou.myAccountSorpFromCustomerServiceEnabled;
var _$useAppConfig = $useAppConfig(),
  agreementsTopic = _$useAppConfig.rootConfig.global.agreementsTopic;
export var mapAgreements = function mapAgreements(data) {
  var _data$agreements, _data$agreements2, _data$agreements4;
  var agreements = [];
  if ((_data$agreements = data.agreements) !== null && _data$agreements !== void 0 && _data$agreements.ppdAgreement) {
    pushIntoAgreements(agreements, "EMAIL", "ESHOP", "N");
    pushIntoAgreements(agreements, "SMS", "ESHOP", "N");
  }
  if ((_data$agreements2 = data.agreements) !== null && _data$agreements2 !== void 0 && _data$agreements2.mktAgreement) {
    pushIntoAgreements(agreements, "EMAIL", "ESHOP", "Y");
    pushIntoAgreements(agreements, "SMS", "ESHOP", "N");
  }
  if (myAccountSorpCardCodeToOtpModeEnabled) {
    var _data$agreements3;
    pushIntoAgreements(agreements, "3RD", "CLIENT", (_data$agreements3 = data.agreements) !== null && _data$agreements3 !== void 0 && _data$agreements3.thirdPartyMarketingAgreement ? "Y" : "N");
  }
  if ((_data$agreements4 = data.agreements) !== null && _data$agreements4 !== void 0 && _data$agreements4.loyaltyAgreement) {
    pushIntoAgreements(agreements, "EMAIL", "ESHOP", "Y");
    pushIntoAgreements(agreements, "SMS", "ESHOP", "Y");
    pushIntoAgreements(agreements, "EMAIL", "CP", "Y");
    pushIntoAgreements(agreements, "SMS", "CP", "Y");
    if (!myAccountSorpFromCustomerServiceEnabled) {
      pushIntoAgreements(agreements, "POST", "CP", "Y");
    }
    if (myAccountSorpCardCodeToOtpModeEnabled) {
      var _data$agreements5, _data$agreements6;
      pushIntoAgreements(agreements, "3RD", "CLIENT", (_data$agreements5 = data.agreements) !== null && _data$agreements5 !== void 0 && _data$agreements5.thirdPartyMarketingAgreement ? "Y" : "N");
      pushIntoAgreements(agreements, "EBLOK", "CLIENT", (_data$agreements6 = data.agreements) !== null && _data$agreements6 !== void 0 && _data$agreements6.eblokAgreement ? "Y" : "N");
    }
  }
  return agreements;
};
var generateAgreement = function generateAgreement(channel, topic, value) {
  return {
    channel: channel,
    topic: topic,
    value: value
  };
};
function pushIntoAgreements(agreements, channel, topic, value) {
  var agreement = agreements.find(function (agreementItem) {
    return agreementItem.channel === channel && agreementItem.topic === topic;
  });
  if (agreement) {
    agreement.value = value;
  } else {
    agreements.push(generateAgreement(channel, topic, value));
  }
}
export var mapAgreementsMyAccount = function mapAgreementsMyAccount(data) {
  var agreements = [];
  if (data.isBabyClub) {
    agreements.push(buildBabyClub(data));
  }
  if (data.shop_email !== void 0) {
    agreements.push({
      channel: "EMAIL",
      topic: "ESHOP",
      value: data.shop_email ? "Y" : "N"
    });
  }
  if (data.shop_sms !== void 0) {
    agreements.push({
      channel: "SMS",
      topic: "ESHOP",
      value: data.shop_sms ? "Y" : "N"
    });
  }
  if (data.shop_post !== void 0) {
    agreements.push({
      channel: "POST",
      topic: "ESHOP",
      value: data.shop_post ? "Y" : "N"
    });
  }
  if (data.emailSmsAgreement !== void 0) {
    var _iterator = _createForOfIteratorHelper(agreementsSmsChannel),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var channel = _step.value;
        agreements.push({
          channel: channel,
          topic: agreementsTopic,
          value: data.emailSmsAgreement ? "Y" : "N"
        });
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
  if (data.thirdPartyMarketingAgreement !== void 0) {
    agreements.push({
      channel: "3RD",
      topic: agreementsTopic,
      value: data.thirdPartyMarketingAgreement ? "Y" : "N"
    });
  }
  if (data.eblokAgreement !== void 0) {
    agreements.push({
      channel: "EBLOK",
      topic: agreementsTopic,
      value: data.eblokAgreement ? "Y" : "N"
    });
  }
  if (agreements.length === 0) {
    return void 0;
  }
  return agreements;
};