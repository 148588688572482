import { maxLength, numeric } from "vuelidate/lib/validators/index.js";
export function useNumericValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      return {
        numeric: numeric,
        maxLength: maxLength(6)
      };
    },
    errors: function errors() {
      return {
        numeric: t("validations.This field should be a number"),
        maxLength: t("validations.maxLength", {
          length: 6
        })
      };
    }
  };
}