import { field, on } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import cartItemPrices from './cartItemPrices.js'

const {
  checkout: {
    features: {
      attributeSetEnabled,
      boundProductsEnabled,
      cartItemsAllowBackOrdersEnabled,
      cartItemsMaxQtyNotificationEnabled,
      cartItemsStockEnabled,
      otcOrderPreferenceEnabled,
    },
  },
  rootConfig: {
    global: {
      otcFormEnabled,
    },
  },
} = useAppConfig()

const {
  public: {
    marketplaceEnabled,
  },
} = useRuntimeConfig()

export default field('items', [
  field('id'),
  field('stock'),
  field('quantity'),
  field('drmax_placement', [
    field('pageType'),
    field('name'),
    field('detail'),
    field('type'),
    field('trigger'),
    field('content', [
      field('id'),
      field('campaign'),
      field('source'),
      field('name'),
      field('admaker'),
      field('position'),
      field('kind'),
      field('token'),
      field('fallbackFrom'),
      field('fallbackReason'),
    ]),
  ]),
  field('brand', [
    field('id'),
    field('label'),
  ]),
  field('product', [
    field('id'),
    field('sku'),
    field('name'),
    field('url_key'),
    field('url_path'),
    field('drmax_abcd'),
    field('drmax_shoptype'),
    field('manufacturer'),
    field('manufacturer_name'),
    field('drmax_lowest_price_30'),
    field('drmax_lowest_price_30_manual'),
    field('main_category', [
      field('name'),
      field('path'),
    ]),
    ...marketplaceEnabled
      ? [
          field('drmax_is_marketplace_product'),
          field('drmax_marketplace_seller_id'),
          field('drmax_marketplace_seller_name'),
          field('drmax_marketplace_master_category', [
            field('code'),
            field('name'),
            field('path'),
          ]),
        ]
      : [],
    ...otcFormEnabled || otcOrderPreferenceEnabled ? [field('drmax_otc')] : [],
    ...(attributeSetEnabled) ? [field('attribute_set_id')] : [],
    field('drmax_promo_allow_reserve'),
    field('drmax_action_allow_reserve'),
    field('drmax_pim_status'),
    field('special_price'),
    field('thumbnail', [
      field('url'),
      field('path'),
    ]),
    field('categories', [
      field('id'),
      field('name'),
      field('path'),
      field('url_key'),
    ]),
    field('price', [
      field('exclVatPrice'),
      field('regularPrice', [
        field('adjustments', [
          field('amount', [
            field('currency'),
            field('value'),
          ]),
          field('code'),
          field('description'),
        ]),
        field('amount', [
          field('currency'),
          field('value'),
        ]),
      ]),
    ]),
    field('tier_prices', [
      field('percentage_value'),
      field('qty'),
      field('value'),
    ]),
    field('group_price', [
      field('discount'),
    ]),
    field('special_price'),
    field('special_from_date'),
    field('special_to_date'),
    field('saleQty', [
      field('maxQty'),
      field('minQty'),
      ...cartItemsMaxQtyNotificationEnabled ? [field('maxQtyNotification')] : [],
      ...cartItemsStockEnabled ? [field('stock')] : [],
      ...cartItemsAllowBackOrdersEnabled ? [field('allow_backorders')] : [],
    ]),
    ...boundProductsEnabled ? [field('bound_products_sku')] : [],
  ]),
  cartItemPrices,
  ...on('SimpleCartItem', [
    field('customizable_options', [
      field('label'),
      field('values', [
        field('value'),
        field('label'),
      ]),
    ]),
  ]),
])
