import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { isArray } from "@nsf/core/Utils.js";
import { tokenized } from "@nsf/utils/StringUtils.js";
import { maxLength, required } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  _useAppConfig$validat = _useAppConfig.validations,
  companyNameMaxLength = _useAppConfig$validat.companyNameMaxLength,
  forbiddenWords = _useAppConfig$validat.forbiddenWords;
export function useCompanyNameValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isForbiddenWords = _ref.isForbiddenWords,
        isForbiddenWords = _ref$isForbiddenWords === void 0 ? false : _ref$isForbiddenWords;
      return _objectSpread({
        required: required,
        maxLength: maxLength(companyNameMaxLength)
      }, isForbiddenWords && isArray(forbiddenWords) && {
        doesNotContainForbiddenWords: function doesNotContainForbiddenWords(value) {
          return !forbiddenWords.some(function (word) {
            return tokenized(value.toLowerCase()).includes(word.toLowerCase());
          });
        }
      });
    },
    errors: function errors() {
      return {
        required: t("validations.Company name is required"),
        maxLength: t("validations.Company name can contain a maximum of x characters", {
          length: companyNameMaxLength
        }),
        doesNotContainForbiddenWords: t("validations.Company name contains a forbidden word")
      };
    }
  };
}