import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
export default function (title) {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  var richMetaTitle;
  var queryPage = parseInt(useRoute().query.page) || 0;
  var titleParts = title.split("|");
  if (titleParts.length === 1) {
    richMetaTitle = queryPage ? "".concat(title, " - ").concat(t("catalog.pagination.page count", {
      count: queryPage
    })) : title;
  } else {
    richMetaTitle = titleParts.reduce(function (accumulator, part, index) {
      var acc = accumulator;
      if (index === titleParts.length - 1 && queryPage) {
        acc += "- ".concat(t("catalog.pagination.page count", {
          count: queryPage
        }));
        return acc;
      }
      acc += part;
      return acc;
    }, "");
  }
  return useSeoTitleAppendSuffix(richMetaTitle);
}