/* eslint-disable import/prefer-default-export */
import logger from '@nsf/layer-my-account/logger.ts'
import myAccountServiceClient from '../clients/MyAccountServiceClient.js'
import { filterCoupons, mapDataToCoupon } from '../mappers/CouponsMapper.js'
import { withAuth } from '../utils/authDecorator.js'

export const getCoupons = async () => {
  try {
    const { data, status, ok } = await withAuth(myAccountServiceClient.get)(
      '/clients/me/coupons',
    )
    return {
      ok: true,
      status,
      coupons: data.data && ok ? filterCoupons(mapDataToCoupon(data.data)) : [],
    }
  } catch (e) {
    logger.error('getCoupons failed: %s', e.message)

    return {
      ok: false,
      status: 500,
      coupons: [],
    }
  }
}
