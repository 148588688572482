import { createRestClient } from '@nsf/core/RestClient.js'
import { useAppConfig as $useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

const {
  rootConfig: {
    global: {
      multiStoreEnabled,
    },
  },
} = $useAppConfig()

const {
  public: {
    myAccountServerUrl,
    storeViewCode,
  },
} = useRuntimeConfig()

let headers = {}
if (multiStoreEnabled) {
  headers = {
    'Compdev-Website-Code': storeViewCode || 'drmax',
  }
}

const myAccountServiceClient = createRestClient({
  baseURL: myAccountServerUrl,
  headers,
})

export default myAccountServiceClient
