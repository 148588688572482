import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'
import { mapUser, mapProduct } from '../mappers/watchdogMapper.js'

const pushEventSubscribeStockAlert = (user, product) => {
  const event = {
    event: 'product_watchdog_add',
    user: mapUser(user),
    product_watchdog: {
      products: [
        mapProduct(product),
      ],
    },
  }
  usePushEvent(event)
}

const pushEventDialogWatchdog = (product) => {
  const event = {
    event: 'product_watchdog_dialogView',
    product_watchdog: {
      products: [
        mapProduct(product),
      ],
    },
  }
  usePushEvent(event)
}

const pushEventRemoveStockAlert = (user, products) => {
  const event = {
    event: 'product_watchdog_remove',
    user: mapUser(user),
    product_watchdog: {
      products: products?.map((product) => mapProduct(product)),
    },
  }
  usePushEvent(event)
}

const pushEventRemovePriceAlert = (user, products) => {
  const event = {
    event: 'product_priceWatch_remove',
    user: mapUser(user),
    product_watchdog: {
      products: products?.map((product) => mapProduct(product)),
    },
  }
  usePushEvent(event)
}

const pushEventAddPriceAlert = (user, product, price) => {
  const event = {
    event: 'product_priceWatch_add',
    user: mapUser(user),
    product_priceWatch: {
      price,
      products: [
        mapProduct(product),
      ],
    },
  }
  usePushEvent(event)
}

const pushPriceAlertModalView = (user, products) => {
  const event = {
    event: 'product_priceWatch_view',
    product_priceWatch: {
      products: products.map((product) => mapProduct(product)),
    },
  }
  if (user) {
    event.user = mapUser(user)
  }
  usePushEvent(event)
}

export {
  pushEventSubscribeStockAlert,
  pushEventDialogWatchdog,
  pushEventRemoveStockAlert,
  pushEventAddPriceAlert,
  pushPriceAlertModalView,
  pushEventRemovePriceAlert,
}
