import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { isEmptyValue, toCamel, toStartCase } from "@nsf/core/Utils.js";
import { persooLoaderLabel } from "@nsf/layer-product-relationship/app/composables/useRelationshipLoader.ts";
export var IsRelationshipTypeProps = {
  title: {
    type: String,
    default: null
  },
  isTitleSmall: {
    type: Boolean,
    default: false
  },
  isAddToCartModalSimple: {
    type: Boolean,
    default: false
  },
  products: {
    type: Array,
    default: function _default() {
      return [];
    }
  },
  gtmList: {
    type: String,
    default: ""
  }
};
export default function useIsRelationshipType(props) {
  var _useAppConfig = useAppConfig(),
    persooConfig = _useAppConfig.productRelationshipLoaderPersoo;
  var persooMeasuringData = ref({});
  var initMeasurePersooData = function initMeasurePersooData() {
    var persooProduct = props.products.find(function (pr) {
      return pr.loaderLabel === persooLoaderLabel;
    });
    if (persooProduct) {
      var _persooConfig;
      var persooOfferId = (_persooConfig = persooConfig["persooOfferId".concat(toStartCase(toCamel(persooProduct.loaderType)).replace(/ /g, ""))]) !== null && _persooConfig !== void 0 ? _persooConfig : "";
      persooMeasuringData.value = {
        "class": "persooLocation",
        "data-location-id": "rawData",
        "data-offer-id": persooOfferId
      };
    }
  };
  initMeasurePersooData();
  onMounted(function () {
    nextTick(function () {
      if (!isEmptyValue(persooMeasuringData.value) && window.persoo) {
        window.persoo("updateLocationTracking");
      }
    });
  });
  return {
    persooMeasuringData: persooMeasuringData
  };
}