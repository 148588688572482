const pickupInstructions = [
  {
    icon: 'pharmacy',
    fragments: [
      { text: 'pickupSelect.config.pharmacy.pickupInstructionsPharmacyText1' },
      {
        text: 'pickupSelect.config.pharmacy.pickupInstructionsPharmacyText2',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'pickupSelect.config.pharmacy.pickupInstructionsSmsText1',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'pickupSelect.config.pharmacy.pickupInstructionsCalendarText1' },
      {
        text: 'pickupSelect.config.pharmacy.pickupInstructionsCalendarText2',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: 'pickupSelect.config.pharmacy.orderLabel',
  reservationLabel: 'pickupSelect.config.pharmacy.reservationLabel',
  unavailablePickupLabel: 'pickupSelect.config.pharmacy.unavailablePickupLabel',
  selectedPlaceLabel: 'pickupSelect.config.pharmacy.selectedPlaceLabel',
  confirmButtonLabel: 'pickupSelect.config.pharmacy.confirmButtonLabel',
  getTitle() {
    return this.title
  },
  // getSubtitle(place) {
  //   return [place.location.street, place.location.city].join(', ')
  // },
  getSubtitle() {
    return ''
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-pharmacy-enabled.svg',
    disabled: 'pin-pharmacy-disabled.svg',
    selected: 'pin-pharmacy-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/cac.svg',
}
