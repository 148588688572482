import { setState } from '@nsf/core/Store.js'
import {
  getFavoritePaymentMethod, getFavoriteShippingMethod, setFavoritePaymentMethod, setFavoriteShippingMethod,
} from '@nsf/layer-my-account/repositories/FavoriteMethodsRepository.js'

export default {
  namespaced: true,

  state: () => ({
    payment: { code: '' },
    shipping: {
      methodCode: '', carrierCode: '', pickupPlaceId: '', pickUpPlaceIcon: '', courierId: '',
    },
    paymentMethods: [],
    shippingMethods: [],
    isLoaded: false,
  }),

  mutations: {
    setState,

    storePaymentMethods(state, paymentMethods) {
      state.paymentMethods = paymentMethods
    },

    storeShippingMethods(state, shippingMethods) {
      state.shippingMethods = shippingMethods
    },
  },

  getters: {
    favoriteShippingMethodStored: (state) => state.shipping,
    favoritePickupPlaceIdStored: (state) => state.shipping.pickupPlaceId,
    favoritePaymentMethodStored: (state) => state.payment,
  },

  actions: {
    async loadFavoriteMethods({ commit, rootState }) {
      if (!rootState['my-account/user'].user.clientId) {
        return null
      }
      const [paymentMethodObj, shippingMethodObj] = await Promise.all([
        getFavoritePaymentMethod(),
        getFavoriteShippingMethod(),
      ])

      if (!paymentMethodObj.ok || !shippingMethodObj.ok) {
        return null
      }

      return commit('setState', {
        isLoaded: true,
        payment: {
          code: paymentMethodObj.paymentMethod.code,
        },
        shipping: {
          methodCode: shippingMethodObj.shippingMethod.methodCode,
          carrierCode: shippingMethodObj.shippingMethod.carrierCode,
          pickupPlaceId: shippingMethodObj.shippingMethod.pickupPlaceId,
          courierId: shippingMethodObj.shippingMethod?.courierId,
          pins: shippingMethodObj.shippingMethod?.pins,
        },
      })
    },

    async saveFavoritePaymentMethod({ commit }, { code }) {
      const { ok } = await setFavoritePaymentMethod({ code })
      if (ok) {
        commit('setState', {
          payment: {
            code,
          },
        })
      }

      return { ok }
    // handle error for UX?
    },

    async saveFavoriteShippingMethod({ commit }, {
      methodCode, carrierCode, pickupPlaceId, pickUpPlaceIcon = '', courierId = '',
    }) {
      const { ok } = await setFavoriteShippingMethod({
        methodCode, carrierCode, pickupPlaceId, courierId,
      })

      if (ok) {
        commit('setState', {
          shipping: {
            methodCode,
            carrierCode,
            pickupPlaceId,
            pickUpPlaceIcon,
            courierId,
          },
        })
      }

      return { ok }
    // handle error for UX?
    },

    async saveFavoriteMethods({ dispatch }, { payment, shipping }) {
      const promises = []
      if (payment) {
        promises.push(dispatch('saveFavoritePaymentMethod', payment))
      }

      if (shipping) {
        promises.push(dispatch('saveFavoriteShippingMethod', shipping))
      }

      const response = await Promise.all(
        promises,
      )

      return {
        paymentOk: payment ? response[0].ok : true,
        shippingOk: shipping ? response[payment ? 1 : 0].ok : true,
      }
    },
  },
}
