import { isAscendingCharacterSequence, isCountryPhoneNumber, isDescendingCharacterSequence, isSameCharacterSequence } from "@nsf/core/Utils.js";
import { useAppConfig as $useAppConfig } from "@nsf/use/composables/useAppConfig.js";
import { useCountryFlags } from "@nsf/use/composables/useCountryFlags.js";
import { requiredIf } from "vuelidate/lib/validators/index.js";
var _$useAppConfig = $useAppConfig(),
  phoneCodeEnabled = _$useAppConfig.rootConfig.global.phoneCodeEnabled;
var _useCountryFlags = useCountryFlags(),
  isCZ = _useCountryFlags.isCZ;
export function usePhoneNumberValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isRequired = _ref.isRequired,
        isRequired = _ref$isRequired === void 0 ? false : _ref$isRequired,
        _ref$innerPhoneCode = _ref.innerPhoneCode,
        innerPhoneCode = _ref$innerPhoneCode === void 0 ? "" : _ref$innerPhoneCode,
        _ref$hasServerValidat = _ref.hasServerValidationError,
        hasServerValidationError = _ref$hasServerValidat === void 0 ? false : _ref$hasServerValidat;
      return {
        requiredIf: requiredIf(function () {
          return isRequired;
        }),
        valid: function valid(value) {
          if (hasServerValidationError) {
            return false;
          }
          if (!value) {
            return true;
          }
          if (isCZ) {
            return isCountryPhoneNumber(value, innerPhoneCode) && !isSameCharacterSequence(value) && !isAscendingCharacterSequence(value) && !isDescendingCharacterSequence(value);
          }
          return isCountryPhoneNumber(value, innerPhoneCode);
        },
        validWithCodeEnabled: function validWithCodeEnabled(value) {
          if (phoneCodeEnabled) {
            if (!value) {
              return true;
            }
            if (!innerPhoneCode) {
              return false;
            }
          }
          return true;
        }
      };
    },
    errors: function errors() {
      return {
        requiredIf: t("validations.Phone number is required"),
        valid: t("validations.Phone number is not valid"),
        validWithCodeEnabled: t("validations.Phone code is missing")
      };
    }
  };
}