import "core-js/modules/es.array.concat.js";
export var useUser = function useUser() {
  var store = useStore();
  var user = computed(function () {
    return store.state["my-account/user"].user;
  });
  var isLoggedIn = computed(function () {
    return store.state["my-account/user"].isLoggedIn;
  });
  var userName = ref(user.value.loggedIn ? "".concat(user.value.firstName, " ").concat(user.value.lastName) : "");
  return {
    user: user,
    userName: userName,
    isLoggedIn: isLoggedIn
  };
};