/* eslint-disable camelcase */

import icons from '../icons.mjs'

export default {
  shippingIcons: {
    ...icons.shippingIcons,
  },
  paymentIcons: {
    ...icons.paymentIcons,
  },
}
