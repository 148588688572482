export default {
  features: {
    addressFromLocalStorageEnabled: true,
    addressValidationInRegistrationEnabled: true,

    agreementsEblokEnabled: false,
    agreementsEmailSmsEnabled: false,
    agreementsGeneralProgramTermsTextEnabled: false,
    agreementsPersonalDataEnabled: true,
    agreementsSmsChannel: ['EMAIL'],
    agreementsStaffinoEnabled: false,
    agreementsThirdPartyMarketingEnabled: false,

    babyClubEnabled: false,
    birthDateEnabled: true,
    birthDateOverEnabled: 18,
    birthDateValidateOnSubmit: true,

    companyDataPrefillEnabled: false,
    companyItInvoicingEnabled: false,

    competitionNoticeEnabled: false,
    competitionService: {
      competitionImagePath: '',
    },

    correspondenceEmailEnabled: false,
    customerSupportWhatsappChannelEnabled: false,
    degreesEnabled: false,
    discountEnabled: false,

    favoriteFilterCategoriesIds: [
      1222, 634, 788, 821, 932, 1053, 1106, 1132, 1149, 1191, 1200,
    ],

    favoritePharmaciesEnabled: false,
    favoriteProductsEnabled: false,
    favoritesV2Enabled: false,

    returnHygienicAgreementEnabled: false,

    loyaltyCardBackImagePath: '',
    loyaltyCardCodeLengthCheckEnabled: false,
    loyaltyCardImagePath: '/loyalty-card/general-loyalty-card.jpg',
    loyaltyCardMyProfileImagePath: '/images/loyalty-card/loyalty-card-my-profile-red.svg',
    loyaltyCardV2: false,

    cardCodeToOtpModeEnabled: false,
    loyaltyCardNumberDigits: 9,
    loyaltyCardNumberStaticPrefix: '++',
    loyaltyProgramBenefitsCount: 0,
    myAccountSorpFromCustomerServiceEnabled: false,
    standAloneCardModeEnabled: false,
    mktPpdUnified: true,
    zipCodeRequired: true,
    checkPersonalDetailsCityFieldEnabled: false,
    accountInfoSorpUpgradeEnabled: false,

    phoneNumberRequired: false,
    phoneNumberSorpCheckEnabled: false,
    phoneCodeEnabled: true,

    priceEnabled: true,
    priceSummaryEnabled: true,
    registrationAddressEnabled: false,
    removeUserEnabled: false,
    returnAndComplaintTitleSplitEnabled: false,
    returnIbanFieldEnabled: false,
    showNotificationSettings: false,

    sexEnabled: true,
    sexThirdOptionEnabled: true,
    sexRequiredEnabled: false,
    typingInLoginEmailEnabled: false,
    unitPriceEnabled: true,
    ordersFilterFromEshopEnabled: false,

    emailRestrictedDomainEnabled: false,

    surveyEnabled: false,
    userFillableDetails: {
      pregnant: false,
      breastFeeding: false,
      allergies: false,
      treatments: false,
      height: false,
      weight: false,
    },
    emailWhisperer: {
      enabled: true,
      topLevelDomains: ['com', 'cz', 'sk', 'pl', 'it', 'org', 'ro'],
      domains: ['wp.pl', 'onet.pl', 'interia.pl', 'o2.pl', 'poczta.fm', 'wirtualna.poczta.pl', 'tlen.pl', 'gmail.com', 'icloud.com', 'outlook.com', 'yahoo.com', 'aol.com'],
      secondLevelDomains: [],
    },
  },
}
