import logger from '@nsf/base/logger.js'
import flatMap from 'lodash/flatMap.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import PlacementBannerWidgetSliderLoader from '@nsf/layer-product-relationship-loader-placement/app/loaders/banners/placementBannerWidgetSliderLoader.ts'
import PlacementSearchSuggestBannerLoader from '@nsf/layer-product-relationship-loader-placement/app/loaders/banners/placementSearchSuggestBannerLoader.ts'
import { mapDataToBanner } from '../mappers/BannerMapper.js'

const {
  public: {
    placementBannersEnabled,
    placementSuggestBannersEnabled,
  },
} = useRuntimeConfig()

const {
  rootConfig: {
    elastic: {
      indices: {
        promoActionsIndex,
      },
    },
  },
} = useAppConfig()

// Sort bannery by priority first. If 2 banners have same priority, then sort by banner_id.
// Sort by priority is DESC, sort by banner_id is ASC
export const sortBanners = (banners) => banners.sort((x, y) => {
  const n = (y.priority || 0) - (x.priority || 0)

  if (n !== 0) {
    return n
  }

  return x.banner_id - y.banner_id
})

export const getHomepageBanners = async (ctx) => {
  try {
    if (placementBannersEnabled) {
      const placementDataLoader = PlacementBannerWidgetSliderLoader.bind({ ctx })
      const placementData = await placementDataLoader('homepage')
      const mapped = mapDataToBanner(placementData.banners)
      return { banners: mapped }
    }

    const promoActions = await Query.promoActions()
      .only('homepage_banners')
      .exists('homepage_banners')
      .getAll()

    const banners = sortBanners(flatMap(promoActions, (promoAction) => promoAction.homepage_banners))
    const mapped = mapDataToBanner(banners)

    return { banners: mapped }
  } catch (e) {
    logger.withTag('elastic').error('getHomepageBanners() failed: %s', e.message)

    return { banners: [] }
  }
}

export const getSalesBanners = async () => {
  try {
    const promoActions = await Query.promoActions()
      .only('sales_banners')
      .exists('sales_banners')
      .getAll()

    const banners = sortBanners(flatMap(promoActions, (promoAction) => promoAction.sales_banners))
    const mapped = mapDataToBanner(banners)

    return { banners: mapped }
  } catch (e) {
    logger.withTag('elastic').error('getSalesBanners() failed: %s', e.message)

    return { banners: [] }
  }
}

export const getSuggesterBanner = async (keyword) => {
  try {
    // search-suggest-banner
    if (placementSuggestBannersEnabled) {
      const placementDataLoader = PlacementSearchSuggestBannerLoader.bind({ ctx: this })
      const placementData = await placementDataLoader('homepage')
      if (placementData.banners.length > 0) {
        const mapped = mapDataToBanner(placementData.banners)

        return mapped[0]
      }

      return null
    }

    const query = Query.promoActions()
      .only('suggester_banners')
      .exists('suggester_banners')

    if (keyword === '') {
      // eslint-disable-next-line no-shadow
      query.mustNot((query) => query.exists('suggester_banners.keywords'))
    } else {
      query.where(
        'suggester_banners.keywords',
        keyword,
      )
    }

    const banners = await query.get()

    if (banners.length === 0) {
      return null
    }

    const mapped = mapDataToBanner(banners, {}, true)

    return mapped[0]?.suggesterBanners?.[0]
  } catch (e) {
    logger.withTag('elastic').error('getSuggesterBanner() failed: %s', e.message)

    return null
  }
}

export const getBanners = async (ids) => {
  try {
    if (!ids?.length) {
      return { banners: [] }
    }

    // eslint-disable-next-line no-underscore-dangle
    const query = new Query('promo_action')._setIndex(promoActionsIndex)

    const promoActions = await query
      .whereIn(
        'id', ids,
      )
      .size(ids.length)
      .get()

    const banners = []

    for (const promoAction of promoActions) {
      if (promoAction?.cms_timed_promotion_banners?.length) {
        banners.push(promoAction?.cms_timed_promotion_banners[0])
      } else {
        let found = false
        Object.keys(promoAction).forEach((key) => {
          if (Array.isArray(promoAction[key]) && promoAction[key].length > 0 && !found) {
            banners.push(promoAction[key][0])
            found = true
          }
        })
      }
    }

    return { banners }
  } catch (e) {
    logger.withTag('elastic').error('getBanners() failed: %s', e.message)

    return { banners: [] }
  }
}
