import dayjs from 'dayjs'

export function birthDateOver18(value: string) {
  return birthDateOver(18, value)
}

export function birthDateBefore18(value: string) {
  return !birthDateOver18(value)
}

export function birthDateOver(value: number, birthdate: string) {
  return dayjs(birthdate)
    .isBefore(dayjs()
      .startOf('date')
      .subtract(
        value,
        'year',
      ))
}
