import { field, variable, Mutation } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('priceAlertUnsubscribeAll')
  .where('input', {
    email: variable('email', REQUIRED_STRING),
  })
  .fields([
    field('message'),
  ])
