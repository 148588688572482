import { useAppConfig as $useAppConfig } from "@nsf/use/composables/useAppConfig.js";
import { maxLength, requiredIf } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  _useAppConfig$validat = _useAppConfig.validations,
  streetNumberValid = _useAppConfig$validat.streetNumberValid,
  streetNumberContainsNumber = _useAppConfig$validat.streetNumberContainsNumber;
var _$useAppConfig = $useAppConfig(),
  streetNumberEnabled = _$useAppConfig.rootConfig.global.streetNumberEnabled;
export function useStreetNumberValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      return {
        requiredIf: requiredIf(function () {
          return streetNumberEnabled;
        }),
        maxLength: maxLength(10),
        valid: function valid(value) {
          return !value || !streetNumberEnabled ? true : streetNumberValid(value);
        },
        streetNumberContainsNumber: streetNumberContainsNumber
      };
    },
    errors: function errors() {
      return {
        requiredIf: t("validations.Street number is required"),
        maxLength: t("validations.Street number can contain a maximum of x characters", {
          length: 10
        }),
        valid: t("validations.Street number is not valid"),
        streetNumberContainsNumber: t("validations.Street number must contain at least one number")
      };
    }
  };
}