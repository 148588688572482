import "core-js/modules/es.regexp.exec.js";
import { required } from "vuelidate/lib/validators/index.js";
export function useSkDicValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      return {
        required: required,
        valid: function valid(value) {
          return /^[124][0-9]{9}$/.test(value);
        },
        length: function length(value) {
          return (value === null || value === void 0 ? void 0 : value.length) === 10;
        }
      };
    },
    errors: function errors() {
      return {
        required: t("validations.SK DIC is required"),
        valid: t("validations.SK DIC is not valid"),
        length: t("validations.SK DIC must have 10 digits")
      };
    }
  };
}