export enum PlacementType {
  CATEGORY = 'category',
  CATEGORY_TOP = 'category-top',
  ELEADER = 'eleader',
  HOMEPAGE_TOP = 'homepage-top',
  PRE_CART = 'pre-cart',
  LAST_VIEWED = 'last-viewed',
  CROSSELL = 'crossell',
  CART_CROSSELL = 'cart-crossell',
  RELATED = 'related',
  HERO = 'hero',
}

export enum PageType {
  CATEGORY = 'category',
  HOMEPAGE = 'homepage',
  CART = 'cart',
  PRODUCT_DETAIL = 'productDetail',
}

export interface ProductRelationshipLoaderPlacementState {
  viewId: string | null
}

export type PlacementTypeValues = PlacementType | string;

export type PageTypeValues = PageType | string;

export interface PlacementOptions {
  categoryName?: string
  productSkus?: string[]
  categoryId?: string
  categoryPath?: string
  higherCategoryId?: string
  limit?: number
  pageType?: PageTypeValues
  entityType?: 'products' | 'banners'
  apiVersion?: string
}

export interface PlacementContent {
  source: string
  admaker: string | null
  id: string | null
  campaign: string | null
  name: string | null
  kind: string
  token: string | null
  type: string
  fallbackReason?: string | null
  fallbackFrom?: string | null
}

export interface PlacementProduct {
  productSKU: string
  content: PlacementContent
}

export interface PlacementBanner {
  content: PlacementContent
  description: string
  url: string
  imageDesktopUrl: string
  imageMobileUrl: string
}
