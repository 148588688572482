import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import { requiredIf } from "vuelidate/lib/validators/index.js";
export function useFiscalCodeValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isRequired = _ref.isRequired,
        isRequired = _ref$isRequired === void 0 ? false : _ref$isRequired,
        _ref$length = _ref.length,
        _length = _ref$length === void 0 ? 16 : _ref$length;
      return {
        requiredIf: requiredIf(function () {
          return isRequired;
        }),
        length: function length(value) {
          if (!isRequired && !value) {
            return true;
          }
          return (value === null || value === void 0 ? void 0 : value.length) === _length;
        },
        valid: function valid(value) {
          if (!isRequired && !value) {
            return true;
          }
          return new RegExp("^[A-Za-z0-9]{".concat(_length, "}$")).test(value);
        }
      };
    },
    errors: function errors() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref2$length = _ref2.length,
        length = _ref2$length === void 0 ? 16 : _ref2$length;
      return {
        requiredIf: t("validations.Fiscal code is required"),
        valid: t("validations.Fiscal code is not valid"),
        length: t("validations.Fiscal code must have {0} characters", [length])
      };
    }
  };
}