/* eslint-disable import/prefer-default-export */
import logger from '@nsf/layer-my-account/logger.ts'

export const initRecaptcha = async (ctx) => {
  if (!ctx || !ctx.$config.public.recaptchaEnabled) {
    return
  }
  try {
    await ctx.$recaptcha.init()
  } catch (e) {
    logger.error('Recaptcha initialization failed with error: `%s`', e)
  }
}
