import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export default function (url) {
  var newUrl = url.replace(/\/\/+/g, "/");
  if (newUrl[0] !== "/") {
    newUrl = "/".concat(newUrl);
  }
  if (newUrl[newUrl.length - 1] === "/") {
    newUrl = newUrl.slice(0, newUrl.length - 1);
  }
  navigateTo(newUrl);
}