import {
  field,
  Query,
  variable,
  on,
} from '@nsf/core/GraphQL.js'
import { getCookie } from '@nsf/utils/CookieUtils.js'
import { isProd } from '@nsf/core/Utils.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'

const agreementFragment = [
  field('channel'),
  field('topic'),
  field('value'),
]

const consentsFragment = [
  field('agreements', agreementFragment),
  field('consents', [
    field('id'),
    field('required'),
    field('text'),
    field('relatedAgreements', agreementFragment),
  ]),
]

export default () => {
  const accessToken = getCookie(isProd() ? 'liveToken' : 'stageToken')

  const consentsField = field('consents', consentsFragment)
    .where('drmaxQuoteType', variable('drmaxQuoteType', 'QuoteType!'))
    .where('orderType', variable('orderType', 'OrderType!'))

  let query = Query.named('customer')
    .toCheckoutGateway()
    .fields([
      ...on('Customer', [consentsField]),
      ...on('CustomerNotFound', [consentsField]),
    ])
    .where('email', variable('email', REQUIRED_STRING))

  if (accessToken) {
    query = query.where('accessToken', accessToken)
  }

  return query
}
