import { email, requiredIf } from "vuelidate/lib/validators/index.js";
export function useItPecValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$itSdi = _ref.itSdi,
        itSdi = _ref$itSdi === void 0 ? "" : _ref$itSdi;
      return {
        requiredIf: requiredIf(function () {
          return !itSdi;
        }),
        itPecValid: email,
        itSdiOptionalValid: function itSdiOptionalValid(value) {
          if (itSdi === "0000000") {
            return value !== "";
          }
          return true;
        }
      };
    },
    errors: function errors() {
      return {
        requiredIf: t("validations.IT PEC or SDI code must be filled in"),
        itPecValid: t("validations.IT PEC has incorrect email format"),
        itSdiOptionalValid: t("validations.IT PEC cannot be empty if the SDI code is 0000000")
      };
    }
  };
}