import "core-js/modules/es.regexp.exec.js";
import { requiredIf } from "vuelidate/lib/validators/index.js";
export function useItSdiValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$itPec = _ref.itPec,
        itPec = _ref$itPec === void 0 ? "" : _ref$itPec,
        _ref$ctx = _ref.ctx,
        ctx = _ref$ctx === void 0 ? {} : _ref$ctx;
      return {
        requiredIf: requiredIf(function () {
          return !itPec;
        }),
        length: function length(value) {
          if (itPec) {
            return !value || (value === null || value === void 0 ? void 0 : value.length) === 7;
          }
          return (value === null || value === void 0 ? void 0 : value.length) === 7;
        },
        itSdiValid: function itSdiValid(value) {
          var regExp = /^[a-zA-Z0-9]{7}$/;
          if (itPec) {
            return !value || regExp.test(value);
          }
          return regExp.test(value);
        },
        itSdiOptionalValid: function itSdiOptionalValid(value) {
          if (itPec) {
            return !value || value === "0000000" || ctx.$v.itSdi.itSdiValid;
          }
          return true;
        }
      };
    },
    errors: function errors() {
      return {
        requiredIf: t("validations.IT PEC or SDI code must be filled in"),
        length: t("validations.IT SDI has to contain 7 characters"),
        itSdiValid: t("validations.IT SDI is not valid"),
        itSdiOptionalValid: t("validations.IT SDI can only be empty or 0000000 if PEC filled in")
      };
    }
  };
}