import { useAppConfig as $useAppConfig } from "@nsf/use/composables/useAppConfig.js";
import { requiredIf } from "vuelidate/lib/validators/index.js";
var _$useAppConfig = $useAppConfig(),
  regionSelectEnabled = _$useAppConfig.rootConfig.global.regionSelectEnabled;
export function useRegionCodeValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      return {
        requiredIf: requiredIf(function () {
          return regionSelectEnabled;
        })
      };
    },
    errors: function errors() {
      return {
        requiredIf: t("validations.Region is required")
      };
    }
  };
}