/* eslint-disable no-param-reassign */
/* eslint-disable complexity */
import { mapDataToModel } from '@nsf/core/Mapper.js'
import { isArray, isNullish, isString } from '@nsf/core/Utils.js'
import { EVENT_NAME as PRODUCT_DETAIL_EVENT_NAME } from '@nsf/gtm/events/productDetail.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { getFinalPrice, getFinalPriceExclVat } from '@nsf/utils/PriceUtils.js'

const {
  checkout: {
    firstPartySellerId,
    firstPartySellerName,
  },
} = useAppConfig()

const {
  public: {
    appUrl,
  },
} = useRuntimeConfig()

const addRatingAttr = (productGTM, productEs) => {
  if (!isNullish(productEs.rating?.count)) {
    productGTM.reviewsCount = productEs.rating.count
    productGTM.reviewsValue = productEs.rating.avg ? Math.round(productEs.rating.avg * 10) / 10 : ''
  }
}

const getName = (name, titleRow1 = null, titleRow2 = null) => {
  const fullName = titleRow1 && titleRow2 ? [titleRow1, titleRow2].join(' ') : null
  return fullName ?? (titleRow1 || name)
}

const getLongestCategory = (productEs) => {
  if (!productEs.category || !Array.isArray(productEs.category)) {
    return ''
  }

  let longestCategory = ''

  for (const category of productEs.category) {
    if (category.path && category.path.length > longestCategory.length) {
      longestCategory = category.path
    }
  }

  return longestCategory
}

const buildName = (productEs, categoryIds) => {
  const mainCategoryPathName = []

  if (!isArray(categoryIds)) {
    return ''
  }

  for (let i = categoryIds.length - 1; i >= 0; i--) {
    // sometimes the ids are string and sometimes number so we use ==
    // eslint-disable-next-line eqeqeq
    const categoryName = productEs.category?.find((c) => c.categoryId == categoryIds[i])?.name
    if (!categoryName) {
      break
    }
    mainCategoryPathName.unshift(categoryName)
  }

  return mainCategoryPathName.join(' / ')
}

export const getCategory = (productEs) => {
  if (!isArray(productEs.category)) {
    if (isString(productEs.category)) {
      return productEs.category
    }
    return null
  }

  const fallbackCategoryIds = getLongestCategory(productEs).split('/')
  const categoryIds = productEs.drmaxMainCategoryPath?.split('/') ?? fallbackCategoryIds

  return buildName(productEs, categoryIds) || buildName(productEs, fallbackCategoryIds)
}

const addAditionalTrackingProps = (drmaxPlacement) => {
  const aditionalProps = {}
  if (drmaxPlacement) {
    const { content, ...rest } = drmaxPlacement
    aditionalProps.content = {
      ...content,
      type: 'placementProducts',
      placement: { ...rest },
    }
  }
  return aditionalProps
}

const getShopAttributes = (productEs) => {
  const shopType = productEs.drmaxShoptype || (productEs.drmaxIsMarketplaceProduct === 1 ? '3p' : '1p')
  const shopId = shopType === '1p' ? '0' : (productEs.drmaxMarketplaceSellerId && String(productEs.drmaxMarketplaceSellerId)) || null
  const shop = shopType === '1p' ? 'Dr. Max' : productEs.drmaxMarketplaceSellerName || null

  return { shopType, shopId, shop }
}

export const formatProduct = (product, event = '', isLoggedIn = false, userAccountType = {}) => {
  const productEs = mapDataToModel(product)
  const discountedAmount = parseFloat(
    (productEs.regularPrice - (productEs.finalPrice ?? productEs.specialPrice)).toFixed(2),
  )
  const specialPrice = discountedAmount === 0
    ? null
    : productEs.specialPrice ?? null

  const productGTM = {
    availablePimStatus: productEs.drmaxPimStatus || null,
    brand: productEs.brandName || null,
    category: getCategory(productEs) || null,

    id: productEs.id,
    sku: productEs.sku,

    discountedAmount: discountedAmount ?? 0,
    fId: productEs.sku,
    finalPrice: getFinalPrice(productEs, isLoggedIn, userAccountType) ?? null,
    finalPriceExclVat: getFinalPriceExclVat(productEs, isLoggedIn, userAccountType) ?? null,
    ...(!productEs.drmaxPlacement && event !== PRODUCT_DETAIL_EVENT_NAME && {
      content: {
        ...productEs.placementContent,
        type: 'content',
      } ?? {
        source: 'eshop',
        kind: 'np',
        type: 'content',
      },
    }),

    mainCategory: productEs.drmaxMainCategoryName || null,

    manufacturer:
      productEs.manufacturerName
      ?? productEs.drmaxManufacturer
      ?? productEs.drmaxManufacter
      ?? productEs.manufacturer
      ?? null,

    masterCategoryCode: productEs.drmaxMarketplaceMasterCategory?.code || null,
    masterMainCategory: productEs.drmaxMarketplaceMasterCategory?.name || null,
    masterCategory: productEs.drmaxMarketplaceMasterCategory?.path || null,

    name: getName(
      productEs.name,
      productEs.drmaxTitleRow1,
      productEs.drmaxTitleRow2,
    ),
    ...(productEs.drmaxPlacement && addAditionalTrackingProps(productEs.drmaxPlacement)),
    abcd: productEs.drmaxAbcd ?? '',

    regularPrice: productEs.regularPrice ?? null, // typo in elastic param name
    regularPriceExclVat: productEs.regularPriceExclVat ?? null,
    specialPrice,
    url: `${appUrl}/${productEs.urlKey}`,
    lowestPrice30Days: productEs.drmaxLowestPrice30Manual ?? productEs.drmaxLowestPrice30 ?? null,
    ...getShopAttributes(productEs),
  }

  // groupDiscountValue and groupDiscountActive can be passed from GQL mapper
  productGTM.groupDiscountActive = productEs.groupDiscountActive ?? productEs.finalPriceLogged < productEs.finalPrice
  // use passed value or compute discount when active
  productGTM.groupDiscountValue = productEs.groupDiscountValue ?? (productEs.finalPriceLogged
    ? parseFloat((productEs.finalPrice - productEs.finalPriceLogged).toFixed(2))
    : 0)

  // this.vat: null,
  addRatingAttr(productGTM, productEs)

  productGTM.shopId = (productEs.drmaxIsMarketplaceProduct === 1)
    ? `${productEs.drmaxMarketplaceSellerId}`
    : `${firstPartySellerId}`

  productGTM.shop = (productEs.drmaxIsMarketplaceProduct === 1)
    ? productEs.drmaxMarketplaceSellerName
    : firstPartySellerName

  productGTM.shopType = productEs.drmaxIsMarketplaceProduct === 1 ? '3p' : '1p'

  return productGTM
}

/**
 * Normalize GQL item to be mapped to same common ES format,
 * especially used for checkout utilizing GraphQL
 *
 *
 * @return {Object}
 * @param item
 */
export const formatGQLProduct = (item) => {
  const productModel = mapDataToModel(item.product)
  // `specialPrice` is handled in ES mapper above (has same key)

  const rowPricePerItem = parseFloat(
    ((item.prices?.rowTotalWithDiscountIncludingTax?.value ?? 0) / item.counter).toFixed(2),
  )
  const finalPrice = rowPricePerItem ?? item.prices.price?.value ?? null
  const finalPriceLogged = productModel.finalPriceLogged ?? finalPrice

  productModel.finalPrice = finalPrice < 0.1 ? 0 : finalPrice
  productModel.finalPriceLogged = finalPriceLogged < 0.1 ? 0 : finalPriceLogged

  if (!productModel.finalPriceExclVat) {
    productModel.finalPriceExclVat = parseFloat(((item.prices?.rowTotal?.value ?? 0) / item.counter).toFixed(2))
  }

  const regularPrice = productModel.price?.regularPrice?.amount?.value
  if (regularPrice) {
    productModel.regularPrice = regularPrice
  }

  const regularPriceExclVat = productModel.price?.exclVatPrice
  if (regularPriceExclVat) {
    productModel.regularPriceExclVat = regularPriceExclVat
  }
  productModel.drmaxMainCategoryName = productModel.mainCategory?.name || null
  productModel.drmaxMainCategoryPath = productModel.mainCategory?.path || null
  productModel.category = productModel.categories?.map((c) => ({ ...c, categoryId: c.id }))
  productModel.brandName = item.brand?.label || null
  productModel.drmaxPlacement = item.drmaxPlacement || null

  // https://mydrmax.atlassian.net/wiki/spaces/GLECOM/pages/3084877908/Group+price+tier+price#GraphQl
  // static values from GQL, active does not reflect customers current logged in state
  productModel.groupDiscountActive = productModel.groupPrice?.discount > 0
  productModel.groupDiscountValue = productModel.groupPrice?.discount ?? 0 // computed on BE

  productModel.drmaxIsMarketplaceProduct = item.product.drmaxIsMarketplaceProduct || 0
  productModel.drmaxMarketplaceSellerName = item.product.drmaxMarketplaceSellerName || ''

  return formatProduct(productModel)
}
