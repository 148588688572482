import { getCurrentInstance } from 'vue'

export default function () {
  const vm = getCurrentInstance()
  if (!vm) {
    throw new Error('must be called in setup')
  }
  return {
    t: (...args) => {
      if (args.length > 1 && typeof args[1] === 'number') {
        return vm.proxy.$tc(...args)
      }
      return vm.proxy.$t(...args)
    },
  }
}
