import { roundUp } from '@nsf/utils/NumberUtils.js'
import { mapDataToTag } from '@nsf/layer-tags/mappers/TagMapper.ts'
import {
  getVisibleTags, getSortedTagsByWeight,
} from '@nsf/layer-tags/app/utils/tagUtils.ts'
import {
  transformDate, extract,
} from '@nsf/core/Utils.js'
import { mapDataToModel } from '@nsf/core/Mapper.js'
import {
  mapDataToCategory, mapDataToMenu,
} from './CategoryMapper.js'
import { mapDataToAuthor } from './AuthorMapper.js'

export const mapDataToArticle = (
  data, translations = {}, seoSideEffects = false,
) => {
  const model = mapDataToModel(
    data,
    {
      post_id: [
        'id',
        Number,
      ],
      externalId: [
        'id',
        Number,
      ],
      author_id: Number,
      full_content: JSON.parse,
      tags: [
        mapDataToTag,
        getVisibleTags,
        getSortedTagsByWeight,
      ],
      published_at: transformDate,
      categories: mapDataToCategory,
      related_posts: [
        'relatedArticles',
        mapDataToArticle,
      ],
      main_category: mapDataToCategory,
      relative_url: [
        'urlKey',
        String,
      ],
      relativeUrl: [
        'urlKey',
        String,
      ],
      menu_root: mapDataToMenu,
      reading_time_in_minutes: [
        'readingTime',
        roundUp,
      ],
      readingTimeInMinutes: [
        'readingTime',
        roundUp,
      ],
      social_networks: [
        'socialNetworks',
        mapDataToSocialNetworks,
      ],
      ...translations,
      author: mapDataToAuthor,
    },
  ) || {}

  // seo side effects used only for article page
  if (model.id && seoSideEffects) {
    model.metaTitle = model.metaTitle || model.title
    model.seo = extract(
      model,
      'metaTitle',
      'metaDescription',
    )
  }
  return model
}

export const mapDataToSocialNetworks = (
  data, translations = {},
) => {
  const model = mapDataToModel(
    data,
    {
      name: String,
    },
  )
  return model
}
