import { useRuntimeConfig } from "@nsf/use/composables/useRuntimeConfig.js";
import createCustomerServiceClient from "./CustomerServiceClient.js";
import myAccountServiceClient from "./MyAccountServiceClient.js";
var _useRuntimeConfig = useRuntimeConfig(),
  customerServiceUrlV2 = _useRuntimeConfig.public.customerServiceUrlV2;
var _useRuntimeConfig2 = useRuntimeConfig(),
  myAccountSorpEnabled = _useRuntimeConfig2.public.myAccountSorpEnabled;
var _useAppConfig = useAppConfig(),
  myAccountSorpFromCustomerServiceEnabled = _useAppConfig.myAccount.features.myAccountSorpFromCustomerServiceEnabled;
export var isSorpCustomerService = myAccountSorpEnabled && myAccountSorpFromCustomerServiceEnabled;
export var client = isSorpCustomerService ? createCustomerServiceClient() : myAccountServiceClient;
export var clientV2 = isSorpCustomerService ? createCustomerServiceClient(customerServiceUrlV2) : myAccountServiceClient;