import { maxLength, requiredIf } from "vuelidate/lib/validators/index.js";
export function useIbanValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isRequired = _ref.isRequired,
        isRequired = _ref$isRequired === void 0 ? true : _ref$isRequired;
      return {
        requiredIf: requiredIf(function () {
          return isRequired;
        }),
        maxLength: maxLength(32)
      };
    },
    errors: function errors() {
      return {
        requiredIf: t("validations.IBAN is required"),
        maxLength: t("validations.IBAN can contain a maximum of x characters", {
          length: 32
        })
      };
    }
  };
}