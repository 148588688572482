import { useAppConfig as $useAppConfig } from "@nsf/use/composables/useAppConfig.js";
import { requiredIf } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  _useAppConfig$validat = _useAppConfig.validations,
  postCodeValid = _useAppConfig$validat.postCodeValid,
  postCodeValidByThreeDigits = _useAppConfig$validat.postCodeValidByThreeDigits,
  postCodeValidByForbiddenCode = _useAppConfig$validat.postCodeValidByForbiddenCode;
var _$useAppConfig = $useAppConfig(),
  zipLength = _$useAppConfig.rootConfig.global.zipLength;
export function usePostCodeValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  var requiredMessage = t("validations.Post code is required");
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isForeignCountry = _ref.isForeignCountrySelected,
        isForeignCountrySelected = _ref$isForeignCountry === void 0 ? false : _ref$isForeignCountry,
        _ref$isRequired = _ref.isRequired,
        isRequired = _ref$isRequired === void 0 ? true : _ref$isRequired;
      return {
        requiredIf: requiredIf(function () {
          return isRequired;
        }),
        // eslint-disable-next-line consistent-return
        valid: function valid(value) {
          if (isForeignCountrySelected) {
            return true;
          }
          if (!value && !isRequired) {
            return true;
          }
          return postCodeValid(value);
        },
        postCodeValidByThreeDigits: postCodeValidByThreeDigits,
        postCodeValidByForbiddenCode: postCodeValidByForbiddenCode
      };
    },
    errors: function errors() {
      return {
        requiredIf: requiredMessage,
        valid: t("validations.Post code has to contain x digits", {
          length: zipLength
        }),
        postCodeValidByThreeDigits: t("validations.Please change the ZIP code to the right format"),
        postCodeValidByForbiddenCode: t("validations.This ZIP code is not allowed to use")
      };
    }
  };
}