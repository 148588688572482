import { setState } from '@nsf/core/Store.js'
import { getDefaultConfig, getConfig } from '../repositories/ConfigRepository.js'

export const state = () => ({
  config: getDefaultConfig(),
})

export const actions = {
  async fetch({ commit }) {
    const config = await getConfig()
    commit('setState', { config: config.config })
  },
}

export const mutations = {
  setState,
}

export const getters = {
  config: (state) => state.config,
}
