import type { PageTypeValues, PlacementTypeValues } from '@nsf/layer-product-relationship-loader-placement/types/product-relationship-loader-placement.ts'
import { PageType, PlacementType } from '@nsf/layer-product-relationship-loader-placement/types/product-relationship-loader-placement.ts'

export default (placement: PlacementTypeValues): PageTypeValues => {
  switch (placement) {
    case PlacementType.CATEGORY_TOP:
      return PageType.CATEGORY
    case PlacementType.ELEADER:
      return PageType.CATEGORY
    case PlacementType.HOMEPAGE_TOP:
      return PageType.HOMEPAGE
    case PlacementType.PRE_CART:
      return PageType.CART
    case PlacementType.LAST_VIEWED:
      return PageType.HOMEPAGE
    case PlacementType.CROSSELL:
      return PageType.PRODUCT_DETAIL
    case PlacementType.RELATED:
      return PageType.PRODUCT_DETAIL
    case PlacementType.CART_CROSSELL:
      return PageType.CART
    case PlacementType.HERO:
      return PageType.HOMEPAGE
    default:
      return placement
  }
}
