import { useAppConfig as $useAppConfig } from "@nsf/use/composables/useAppConfig.js";
var _$useAppConfig = $useAppConfig(),
  _$useAppConfig$rootCo = _$useAppConfig.rootConfig.global.watchdog,
  priceAlertEnabled = _$useAppConfig$rootCo.priceAlertEnabled,
  stockAlertEnabled = _$useAppConfig$rootCo.stockAlertEnabled;
export var useHasAlert = function useHasAlert() {
  var store = useStore();
  var user = computed(function () {
    return store.state["my-account/user"].user;
  });
  var isErrorPriceAlerts = computed(function () {
    return store.state["my-account/alerts"].isErrorPriceAlerts;
  });
  var isErrorStockAlerts = computed(function () {
    return store.state["my-account/alerts"].isErrorStockAlerts;
  });
  var stockAlerts = computed(function () {
    return store.state["my-account/alerts"].stockAlerts;
  });
  var priceAlerts = computed(function () {
    return store.state["my-account/alerts"].priceAlerts;
  });
  var isLoading = computed(function () {
    return !store.state["my-account/alerts"].isAlertsInitialized || !store.state["my-account/user"].isInitialized;
  });
  var config = store.state["_base/config"].config;
  var isPriceAlertEnabled = priceAlertEnabled && config.drmaxPriceNotificationAllow;
  var isStockAlertEnabled = stockAlertEnabled && config.drmaxBackInStockNotificationAllow;
  var isStockOrPriceAlertEnabled = isStockAlertEnabled || isPriceAlertEnabled;
  return {
    isErrorPriceAlerts: isErrorPriceAlerts,
    isErrorStockAlerts: isErrorStockAlerts,
    isLoading: isLoading,
    isPriceAlertEnabled: isPriceAlertEnabled,
    isStockAlertEnabled: isStockAlertEnabled,
    isStockOrPriceAlertEnabled: isStockOrPriceAlertEnabled,
    priceAlerts: priceAlerts,
    removeAllAlerts: function removeAllAlerts(type, email) {
      return store.dispatch("my-account/alerts/removeAllAlerts", {
        type: type,
        email: email
      });
    },
    stockAlerts: stockAlerts,
    user: user
  };
};