import { variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { default as leafletListQuery } from '@nsf/layer-clubs/app/gql/queries/leafletListQuery.js'

export default async (query: string) => {
  const res = await leafletListQuery()
    .where('name', variable('name', REQUIRED_STRING, ''))
    .bind({ name: query })
    .get()

  return res || []
}
