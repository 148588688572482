/**
 * Item group key.
 * @typedef { 'BASIC' | 'FOCUSED' | 'GLOBAL' } ItemGroupKey
 */

/**
 * Group of cart items.
 * @typedef ItemGroup
 * @type {Object}
 * @property {ItemGroupKey} key
 * @property {Object[]} items - cart items
 * @property {Object[]} rules - sales rules applied to the item group
 */

/**
 * Split items into groups based on associated rules. Rules are associated to items by `causedBy` field.
 *
 * @param items
 * @param allRules
 * @returns {[]} Array of three types of groups - a groups of items associated with a certain rule,
 * a groups with no items but containing a generic rule, a group with items not associated to any rule
 */
const mapDataToItemGroups = (
  items, allRules,
) => {
  const remaining = [...items]
  const basicGroup = []
  const focusedGroup = []
  const globalGroup = []

  const pickItems = (ids) => {
    const picked = []

    for (const id of ids) {
      const index = remaining.findIndex((item) => item.id === id)

      if (index >= 0) {
        const [item] = remaining.splice(
          index,
          1,
        )

        picked.push(item)
      }
    }

    return picked
  }

  const subgroupContainsIds = (ids) => (subgroup) => {
    if (!subgroup.causedBy.length) {
      return false
    }

    return subgroup.causedBy.every((id) => ids.includes(id))
  }

  const itemRules = allRules.filter((rule) => rule.type === 'ITEM')
  const otherRules = allRules.filter((rule) => rule.type !== 'ITEM')
  const sortedAllRules = [...itemRules, ...otherRules]

  for (const {
    causedBy: causedByIds,
    isVisibleOnFe,
    ...rule
  } of sortedAllRules) {
    if (isVisibleOnFe === false) {
      // eslint-disable-next-line no-continue
      continue
    }

    if (causedByIds) {
      const matchingSubgroup = focusedGroup.find(subgroupContainsIds(causedByIds))

      if (matchingSubgroup?.rules) {
        matchingSubgroup.rules.push(rule)
      } else {
        focusedGroup.push({
          key: 'FOCUSED',
          causedBy: causedByIds ?? [],
          items: pickItems(causedByIds),
          rules: [rule],
        })
      }
    } else {
      globalGroup.push({
        key: 'GLOBAL',
        items: [],
        rules: [rule],
      })
    }
  }

  if (remaining.length) {
    basicGroup.push({
      key: 'BASIC',
      items: remaining,
      rules: [],
    })
  }

  return [
    ...basicGroup,
    ...focusedGroup,
    ...globalGroup,
  ]
}

export default mapDataToItemGroups
