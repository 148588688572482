import { requiredIf } from "vuelidate/lib/validators/index.js";
export function useCountryCodeValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isCountrySelectC = _ref.isCountrySelectChoiceEnabled,
        isCountrySelectChoiceEnabled = _ref$isCountrySelectC === void 0 ? false : _ref$isCountrySelectC;
      return {
        requiredIf: requiredIf(function () {
          return isCountrySelectChoiceEnabled;
        })
      };
    },
    errors: function errors() {
      return {
        requiredIf: t("validations.Country code is required")
      };
    }
  };
}