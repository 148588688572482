import pushPlacementClickEvent from '@nsf/gtm/events/placementClick.js'
import placementTypeMapper from '@nsf/gtm/mappers/placementTypeMapper.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import HasUser from '@nsf/layer-my-account/mixins/HasUser.js'
import debounce from 'lodash/debounce.js'
import { mapState } from 'vuex'
import pushPlacementViewEvent from '../events/placementView.js'

export const $PlacementView = '$PlacementView'

const {
  gtm: {
    placementView: {
      collectionTimer,
      collectionMaxObjects,
    },
  },
} = useAppConfig()

export default {
  provide() {
    return { [$PlacementView]: this }
  },

  inject: {
    [$PlacementView]: { default: null },
  },

  props: {
    placementPageType: {
      type: String,
      default: null,
    },
    placementName: {
      type: String,
      default: null,
    },
    placementType: {
      type: String,
      default: null,
    },
    trigger: {
      type: String,
      default: null,
    },
    placementDetail: {
      type: String,
      default: null,
    },
    placementDetailId: {
      type: [String, Number],
      default: null,
    },
    persist: {
      type: Boolean,
      default: false,
    },
    handleErrors: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      isLoggedIn: (state) => state['my-account/user'].isLoggedIn,
    }),
    computedPlacementType() {
      return placementTypeMapper(this.placementType)
        || this.$PlacementView?.computedPlacementType
        || null
    },
    computedPageType() {
      return this.placementPageType || this.$PlacementView?.computedPageType || this.$store.getters['gtm/getPageType'] || null
    },
    computedTrigger() {
      return this.trigger || this.$PlacementView?.computedTrigger || null
    },
    computedName() {
      return this.placementName || this.$PlacementView?.computedName || this.$store.getters['gtm/getName'] || null
    },
    computedDetail() {
      return this.placementDetail || this.$PlacementView?.computedDetail || this.$store.getters['gtm/getDetail'] || null
    },
    computedDetailId() {
      return this.placementDetailId || this.$PlacementView?.computedDetailId || this.$store.getters['gtm/getDetailId'] || null
    },
  },

  data() {
    return {
      observedObjects: {
        products: [],
        popups: [],
        coupons: [],
        banners: [],
        nocontent: [],
      },
      sortKey: {
        products: 'position',
        popups: null,
        coupons: null,
        banners: 'content.position',
        nocontent: null,
      },
      debouncedPushEvent: {
        products: null,
        popups: null,
        coupons: null,
        banners: null,
        nocontent: null,
      },
    }
  },

  created() {
    if (this.handleErrors) {
      if (this.$store.getters['gtm/getErrorMessages']?.length) {
        this.pushErrors()
      }

      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === 'gtm/setGtmError') {
          this.pushErrors()
        }
      })
    }
  },

  beforeDestroy() {
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe()
    }
  },

  methods: {
    pushPlacementClickEvent({ data, type }) {
      const placement = {
        ...this.getPlacementData(type),
        type,
      }
      pushPlacementClickEvent(placement, data, this.isLoggedIn, {
        isFullUserAccount: this.isFullUserAccount,
        isLimitedUserAccount: this.isLimitedUserAccount,
      })
    },

    observeObject(obj, type) {
      this.observedObjects[type].push(obj)

      if (this.observedObjects[type].length >= collectionMaxObjects) {
        if (this.debouncedPushEvent[type]) {
          this.debouncedPushEvent[type].cancel()
        }
        this.pushEvent(type, true)
      } else {
        this.pushEvent(type)
      }
    },

    pushErrors() {
      this.$store.getters['gtm/getErrorMessages'].forEach(({ message, type }) => {
        const placement = {
          ...this.getPlacementData('nocontent'),
          name: type,
        }
        pushPlacementViewEvent(placement, { code: message })
      })
      this.$store.dispatch('gtm/clearGtmErrors')
    },

    getPlacementData(type) {
      return {
        ...(this.computedPageType && { pageType: this.computedPageType }),
        ...(this.computedName && { name: this.computedName }),
        ...(this.computedDetail && { detail: this.computedDetail }),
        ...(this.computedDetailId && { detailId: this.computedDetailId }),
        ...(this.computedTrigger && { trigger: this.computedTrigger }),
        viewedAt: Date.now(),
        ...(type && { type }),
      }
    },

    filterDuplicatesById(array) {
      const map = new Map()
      return array.filter((item) => {
        if (!item?.id) {
          return true
        }
        if (!map.has(item.id)) {
          map.set(item.id, true)
          return true
        }
        return false
      })
    },

    getValueByKeyPath(obj, keys) {
      let value = { ...obj }
      for (let i = 0; i < keys.length; i++) {
        if (typeof value !== 'object' || !(keys[i] in value)) {
          return null
        }
        value = value[keys[i]]
      }
      return Number(value) || 0
    },

    pushEvent(type, immediate = false) {
      if (!this.debouncedPushEvent[type]) {
        this.debouncedPushEvent[type] = debounce(() => {
          this.sendPlacementViewEvent(type)
        }, collectionTimer)
      }

      if (immediate) {
        this.sendPlacementViewEvent(type)
      } else {
        this.debouncedPushEvent[type]()
      }
    },

    sendPlacementViewEvent(type) {
      if (this.sortKey[type]) {
        this.observedObjects[type].sort((a, b) => {
          const keys = this.sortKey[type].split('.')
          return this.getValueByKeyPath(a, keys) - this.getValueByKeyPath(b, keys)
        })
      }

      pushPlacementViewEvent(
        this.getPlacementData(type),
        this.filterDuplicatesById(this.observedObjects[type]),
        this.isLoggedIn,
        {
          isFullUserAccount: this.isFullUserAccount,
          isLimitedUserAccount: this.isLimitedUserAccount,
        },
      )
      this.observedObjects[type] = []
    },
  },

  mixins: [
    HasUser,
  ],

  mounted() {
    if (this.persist) {
      this.$store.commit('gtm/setPageInfo', {
        pageType: this.placementPageType,
        detail: this.placementDetail,
        detailId: this.placementDetailId,
        name: this.placementName,
      })
    }
  },

  render() {
    return this.$slots.default
  },
}
