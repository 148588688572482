import { setState } from '@nsf/core/Store.js'
import type { ProductRelationshipLoaderPlacementState } from '@nsf/layer-product-relationship-loader-placement/types/product-relationship-loader-placement.ts'

export default {
  namespaced: true,

  state: () => ({
    viewId: null,
  } as ProductRelationshipLoaderPlacementState),

  getters: {
    viewId(state: ProductRelationshipLoaderPlacementState) {
      return state.viewId
    },
  },

  mutations: {
    setState,
  },

  actions: {
    setViewId(
      { commit }: { commit: (mutation: string, payload: Partial<ProductRelationshipLoaderPlacementState>) => void },
      viewId: string | null,
    ) {
      commit('setState', { viewId })
    },
  },
}
