import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import { requiredIf } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  loyaltyCardNumberDigits = _useAppConfig.myAccount.features.loyaltyCardNumberDigits;
export function useLoyaltyCardNumberValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isRequired = _ref.isRequired,
        isRequired = _ref$isRequired === void 0 ? false : _ref$isRequired;
      return {
        requiredIf: requiredIf(function () {
          return isRequired;
        }),
        length: function length(value) {
          if (!isRequired && !value) {
            return true;
          }
          return (value === null || value === void 0 ? void 0 : value.length) === loyaltyCardNumberDigits;
        },
        valid: function valid(value) {
          if (!isRequired && !value) {
            return true;
          }
          var regex = new RegExp("^[0-9]{".concat(loyaltyCardNumberDigits, "}$"));
          return regex.test(value);
        }
      };
    },
    errors: function errors() {
      return {
        requiredIf: t("validations.Loyalty card number is required"),
        valid: t("validations.Loyalty card number is not valid"),
        length: t("validations.Loyalty card number must have {0} digits", [loyaltyCardNumberDigits])
      };
    }
  };
}