import PersooLastViewedLoader from "@nsf/layer-product-relationship-loader-persoo/app/loaders/PersooLastViewedLoader.ts";
import PlacementHomepageLoader from "@nsf/layer-product-relationship-loader-placement/app/loaders/products/placementHomepageLoader.ts";
export default function () {
  var _useRuntimeConfig = useRuntimeConfig(),
    placementPersonalizedEnabled = _useRuntimeConfig.public.placementPersonalizedEnabled;
  var _useNuxtApp = useNuxtApp(),
    ctx = _useNuxtApp.nuxt2Context;
  if (placementPersonalizedEnabled) {
    return [PlacementHomepageLoader.bind({
      ctx: ctx
    })];
  }
  return [PersooLastViewedLoader];
}