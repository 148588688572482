import { getPlacementProducts } from "@nsf/layer-product-relationship-loader-placement/app/utils/repositories/PlacementRepository.ts";
import { PlacementType } from "@nsf/layer-product-relationship-loader-placement/types/product-relationship-loader-placement.ts";
var _useAppConfig = useAppConfig(),
  placementHomepageLimit = _useAppConfig.productRelationshipLoaderPlacement.placementHomepageLimit;
var placementHomepageLoader = function placementHomepageLoader() {
  var _window$nsf$session$d, _window, _window$nsf, _window$nsf$session, _this$config;
  var userId = (_window$nsf$session$d = (_window = window) === null || _window === void 0 ? void 0 : (_window$nsf = _window.nsf) === null || _window$nsf === void 0 ? void 0 : (_window$nsf$session = _window$nsf.session) === null || _window$nsf$session === void 0 ? void 0 : _window$nsf$session.deviceCache) !== null && _window$nsf$session$d !== void 0 ? _window$nsf$session$d : "";
  var config = (_this$config = this === null || this === void 0 ? void 0 : this.config) !== null && _this$config !== void 0 ? _this$config : {
    limit: placementHomepageLimit
  };
  return getPlacementProducts(PlacementType.LAST_VIEWED, userId, config, this === null || this === void 0 ? void 0 : this.ctx);
};
export default placementHomepageLoader;