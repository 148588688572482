import "core-js/modules/es.regexp.exec.js";
export default defineAppConfig({
  validations: {
    cityAllowedCharacters: function cityAllowedCharacters() {
      return true;
    },
    cityContainsLetter: function cityContainsLetter(value) {
      return /[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/.test(value);
    },
    cityMaxLength: 150,
    companyNameMaxLength: 150,
    crnLength: 10,
    crnNumbersOnly: function crnNumbersOnly(value) {
      return /^[0-9]+$/.test(value);
    },
    crnValid: function crnValid() {
      return true;
    },
    customerNotesAllowedCharacters: function customerNotesAllowedCharacters() {
      return true;
    },
    emailMaxLength: 150,
    firstLastNameAllowedCharacters: function firstLastNameAllowedCharacters(value) {
      return /^[a-zA-Z-'\s\u00C0-\u024F\u1E00-\u1EFF]*$/.test(value);
    },
    firstNameMaxLength: 150,
    forbiddenWords: ["Apteka", "Szpital", "Przychodnia", "Hurtownia"],
    lastNameMaxLength: 150,
    passwordForbiddenKeywords: ["haslo"],
    postCodeValid: function postCodeValid(value) {
      return /^[0-9]{2}-[0-9]{3}$/.test(value);
    },
    postCodeValidByForbiddenCode: function postCodeValidByForbiddenCode() {
      return true;
    },
    postCodeValidByThreeDigits: function postCodeValidByThreeDigits() {
      return true;
    },
    streetAllowedCharacters: function streetAllowedCharacters() {
      return true;
    },
    streetMaxLength: 150,
    streetNumberContainsNumber: function streetNumberContainsNumber(value) {
      return /\d/.test(value);
    },
    streetNumberValid: function streetNumberValid(value) {
      return /^\b(?!.*?\s{2})[0-9a-z\s-/]+$/i.test(value);
    },
    streetValid: function streetValid() {
      return true;
    },
    vatIdForbiddenValues: [],
    vatIdForbiddenWords: function vatIdForbiddenWords() {
      return true;
    },
    vatIdLength: 10,
    vatIdStartsWith: function vatIdStartsWith() {
      return true;
    },
    vatIdValid: function vatIdValid(value) {
      return /^[0-9]{10}$/.test(value);
    }
  }
});