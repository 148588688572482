/* eslint-disable import/prefer-default-export */

export const ERROR_MESSAGES = {
  ERROR_SOMETHING_WENT_WRONG: 'myAccount.errorMessages.Something went wrong',
  ERROR_TOO_MANY_ATTEMPTS: 'myAccount.errorMessages.Too many attempts',

  ERROR_ACCOUNT_ALREADY_EXISTS: 'myAccount.loyaltyCard.An online account already exists for this card',
  ERROR_ACCOUNT_ALREADY_EXISTS_EMAIL: 'myAccount.registration.Account with this e-mail address already exists',
  ERROR_ASSIGNING_LOYALTY_CARD: 'myAccount.registration.Assigning loyalty card could not be proceeded now',
  ERROR_CARD_NUMBER_NOT_FOUND: 'myAccount.loyaltyCard.A card with this number and pin does not exist',
  ERROR_CLIENT_ID_MISSING: 'myAccount.loyaltyCard.Client id is missing',
  ERROR_CLIENT_NOT_EXIST: 'myAccount.password.Client does not exist',
  ERROR_CODE_EXPIRED: 'myAccount.registration.The provided code has expired',
  ERROR_CODE_INCORRECT: 'myAccount.registration.The provided code is incorrect',
  ERROR_CODE_NOT_EXIST: 'myAccount.registration.The provided code does not exist',
  ERROR_CONTACT_CALL_CENTER: 'myAccount.loyaltyCard.Enter details again or contact our call center',
  ERROR_CUSTOMER_ALREADY_HAS_CARD: 'myAccount.loyaltyCard.Customer already has activated card for own account',
  ERROR_CUSTOMER_RECORD_NOT_EXIST: 'myAccount.loyaltyCard.Customer record does not exist in database',
  ERROR_EMAIL_ALREADY_IN_USE: 'myAccount.registration.Email is already in use',
  ERROR_EMAIL_ORDER_MISMATCH: 'myAccount.returns.We are sorry, but email and order number dont match',
  ERROR_FISCAL_CODE_EXISTS: 'myAccount.registration.Exists another client with this fiscal code',
  ERROR_FISCAL_CODE_MISMATCH: 'myAccount.loyaltyCard.Fiscal code does not match',
  ERROR_HASH_INVALID: 'myAccount.Hash is invalid',
  ERROR_INVALID_CARD_CODE: 'myAccount.loyaltyCard.Invalid card code',
  ERROR_LOGIN: 'myAccount.registration.Login error happened during process',
  ERROR_LOYALTY_CARD_ASSIGN: 'myAccount.registration.The loyalty card could not be assigned',
  ERROR_NEW_PASSWORD_TABLET: 'myAccount.tabletRegistration.Your password has not been set, please try again',
  ERROR_PERSONAL_DATA_MISSING: 'myAccount.loyaltyCard.Personal data is missing',
  ERROR_PHONE_IN_USE: 'myAccount.registration.Phone number is already in use',
  ERROR_REGISTRATION_EMAIL_NOT_EXIST: 'myAccount.password.Registration email does not exist',
  ERROR_RESET_PASSWORD: 'myAccount.resetPassword.Password was not reset, please try again',
  ERROR_SESSION_INTERRUPTED: 'myAccount.loyaltyCard.Browsing session interrupted',
  ERROR_UPGRADE_REQUIRED_DATA_MISSING: 'myAccount.registration.Upgrade of the account could not be proceeded because of required data that are missing in the form',
}
