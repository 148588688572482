import { LOADER_LABEL_SEARCH } from '@nsf/catalog/mixins/IsProduct.js'
import { getCategoriesByIds } from '@nsf/catalog/repositories/CategoryRepository.js'
import { getProductsByIds } from '@nsf/catalog/repositories/ProductRepository.js'
import { fetchFilterPrefix, getFilter } from '@nsf/catalog/utils/BuilderUtils.js'
import { isInStock } from '@nsf/catalog/utils/StockUtils.js'
import { Query } from '@nsf/core/ElasticSearch.js'
import { get, set } from '@nsf/core/Storage.js'
import { isString } from '@nsf/core/Utils.js'
import { getToken } from '@nsf/layer-my-account/utils/auth.js'
import logger from '@nsf/search/logger.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { ensureArray } from '@nsf/utils/ArrayUtils.js'
import { uuid } from '@nsf/utils/StringUtils.js'
import jwtDecode from 'jwt-decode'
// eslint-disable-next-line import/no-cycle
import { searchEndpoint as searchServiceEndpoint } from '../repositories/SearchServiceRepository.js'

const {
  algolia: {
    features: {
      analyticsEnabled,
    },
  },
  search: {
    sortSearchResultsByAvailability,
    suggestSearch,
  },
  rootConfig: {
    global: {
      pagination: {
        productsPerPage,
      },
    },
  },
} = useAppConfig()

const INDEX_TYPE_PRODUCTS = 'PRODUCTS'
const ITEM_TYPE_PRODUCTS = 'products'

export const getItemType = (indexType) => {
  let key = ''
  switch (indexType.toLowerCase()) {
    case 'advice':
    case 'articles_advice':
      key = 'advices'
      break
    case 'articles_cms':
      key = 'articles'
      break
    case 'product_lines':
    case 'productlines':
      key = 'productLines'
      break
    default:
      key = indexType.toLowerCase()
      break
  }
  return key
}

export const getEndpoint = (indexType) => {
  let key = ''
  switch (indexType.toLowerCase()) {
    case 'advice':
    case 'articles_advice':
      key = 'advices'
      break
    case 'articles_cms':
      key = 'articles'
      break
    case 'product_lines':
    case 'productlines':
      key = 'product-lines'
      break
    default:
      key = indexType.toLowerCase()
      break
  }
  return key
}

export const normalizeQuery = (query) => query.trim().replace(/\s+/g, ' ')

export const isValidQuery = (query) => {
  if (typeof query === 'string' && query.trim().length >= 2 && query.trim() !== 'undefined') {
    return true
  }
  return false
}

const getQueryResults = async (route, indexType) => {
  let limit = 100
  if (getItemType(indexType) === ITEM_TYPE_PRODUCTS) {
    limit = 1000
  }

  const productFields = ['indexType', 'id', 'sku', 'in_stock']

  return await searchServiceEndpoint(`search/${getEndpoint(indexType)}`, route.query.q || '', {
    limit,
    offset: 0,
    withTotals: 1,
    ...(getItemType(indexType) === ITEM_TYPE_PRODUCTS && { fieldsToRetrieve: productFields }),
  })
}

const sortProductsByAvailability = (products) => [...products].sort((p1, p2) => isInStock(p2) - isInStock(p1))

export const getSuggestSearchLength = (type, defaultValue = 10) => suggestSearch.split(',').find((o) => o.includes(type))
  ?.split(':')?.[1] ?? defaultValue

const getProductsCategories = async (searchProductIds) => {
  try {
    const mainCategoriesIds = await Query.products().whereIn('id', searchProductIds)
      .only(['category_ids'])
      .getAll()

    const allMainCategories = mainCategoriesIds.map((catIds) => catIds.category_ids[1]).filter((catIds) => catIds)

    const filteredMainCategories = [...new Set(allMainCategories)]

    const { categories } = await getCategoriesByIds(filteredMainCategories)

    return { categories }
  } catch (e) {
    return {
      categories: [],
    }
  }
}

const mapHitsToProducts = async (hits, route, store) => {
  const { _filterPrefix } = await fetchFilterPrefix.call({ _url: route.path })
  const { search, range } = route.query
  const filter = getFilter(route.query.filter, { _filterPrefix })
  const page = Number(route.query.page) || 1
  const sort = route.query.sort === 'relevance:asc' ? undefined : route.query.sort

  const hasFilter = !!(ensureArray(range).length || isString(search) || ensureArray(filter).length || sort)

  if (route.query.q !== store.state.search.searchingKeyword) {
    store.commit('search/setSearchingKeyword', route.query.q)

    const productIds = hits.hits.map((product) => product.id)
    const availableProductsIds = []
    const unavailableProductsIds = []

    // we will obtain current availability for product from ES
    const { products } = await getProductsByIds(productIds, {
      size: 1000,
      fields: ['drmax_pim_status', 'stock', 'drmax_stock_salable_qty', 'backorders', 'id'],
    })

    for (const product of products) {
      if (isInStock(product)) {
        availableProductsIds.push(product.id)
      } else {
        unavailableProductsIds.push(product.id)
      }
    }

    store.commit('search/setSearchingProductIds', [...availableProductsIds, ...unavailableProductsIds])
  }

  const searchProductIds = store.state.search.searchingProductIds

  const fromProduct = (page - 1) * productsPerPage
  const toProduct = fromProduct + productsPerPage

  const queryableProductIds = hasFilter ? searchProductIds : searchProductIds.slice(fromProduct, toProduct)

  const [{ products, query: productsQuery, total }, { categories }] = await Promise.all([
    getProductsByIds(queryableProductIds, {
      from: hasFilter ? fromProduct : 0,
      size: productsPerPage,
      sort,
      filter,
      search,
      range,
    }),
    getProductsCategories(searchProductIds),
  ])

  const productHits = { ...hits }

  productHits.mainCategories = categories
  productHits.total = hasFilter ? (total?.value ?? total) : searchProductIds.length
  productHits.filterPrefix = _filterPrefix

  store.commit('search/setTotalProducts', productHits.total)

  // move unavailable results to the end if needed
  const hitProducts = !hasFilter && sortSearchResultsByAvailability
    ? sortProductsByAvailability(products)
    : products

  productHits.hits = hitProducts.map((product) => ({
    ...product,
    indexType: INDEX_TYPE_PRODUCTS,
    queryID: analyticsEnabled ? hits.queryID : undefined,
    loaderLabel: LOADER_LABEL_SEARCH,
  }))

  if (hasFilter) {
    productHits.productsQuery = productsQuery
  } else {
    const q = Query.fromString(productsQuery)
    // eslint-disable-next-line no-underscore-dangle
    const productsIdTermIndex = q._must.findIndex((term) => term?.terms?.id)
    // eslint-disable-next-line no-underscore-dangle
    q._must.splice(productsIdTermIndex, 1)
    q.whereIn('id', searchProductIds)
    productHits.productsQuery = q.toString()
  }

  return {
    ...productHits,
    defaultSortBy: sort || 'relevance:asc',
    page,
  }
}

export const getSearchingHits = async (route, indexType, store) => {
  const hitsResponse = await getQueryResults(route, indexType)
  const hitsTotal = hitsResponse?.totals?.types

  const totalResults = {
    products: hitsTotal?.products || 0,
    brands: hitsTotal?.brands || 0,
    categories: hitsTotal?.categories || 0,
    articles: hitsTotal?.articles || 0,
    advices: hitsTotal?.advices || 0,
    productLines: hitsTotal?.product_lines || 0,
  }

  if (getItemType(indexType) === ITEM_TYPE_PRODUCTS) {
    const response = await mapHitsToProducts(hitsResponse, route, store)

    totalResults.products = response.total
    store.commit('search/setTotalResults', totalResults)

    return response
  }

  store.commit('search/setTotalResults', totalResults)

  return {
    ...hitsResponse,
    defaultSortBy: 'relevance:asc',
    page: 1,
  }
}

export const getUserToken = () => {
  try {
    // no user token for SSR
    if (typeof window === 'undefined') {
      return null
    }

    const userToken = getToken()

    // we will check if user is logged-in and if yes, we will get JWT token and parse clientId
    if (userToken) {
      const parsedUserData = jwtDecode(userToken)

      if (parsedUserData.clientId) {
        return parsedUserData.clientId
      }
    }

    // we will check if there is already generated token in localStorage. If yes, we will use it.
    // If not, we will generate it.
    if (!get('search-user-token') && !get('algolia-user-token')) {
      const newToken = uuid().replaceAll('-', '')
      set('search-user-token', newToken)
    }

    if (get('algolia-user-token')) {
      return get('algolia-user-token')
    }

    return get('search-user-token')
  } catch (e) {
    logger.error(' getUserToken failed: %s', e.message)
    return null
  }
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
