import { requiredIf } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  _useAppConfig$validat = _useAppConfig.validations,
  crnLength = _useAppConfig$validat.crnLength,
  crnMaxLength = _useAppConfig$validat.crnMaxLength,
  crnNumbersOnly = _useAppConfig$validat.crnNumbersOnly,
  crnValid = _useAppConfig$validat.crnValid;
export function useCrnValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isRequired = _ref.isRequired,
        isRequired = _ref$isRequired === void 0 ? true : _ref$isRequired;
      return {
        requiredIf: requiredIf(function () {
          return isRequired;
        }),
        valid: function valid(value) {
          if (!value || !crnValid) {
            return true;
          }
          return crnValid(value);
        },
        numbersOnly: function numbersOnly(value) {
          if (!value) {
            return true;
          }
          return crnNumbersOnly(value);
        },
        length: function length(value) {
          if (!value || !crnLength) {
            return true;
          }
          return value.length === crnLength;
        },
        maxLength: function maxLength(value) {
          if (!value || !crnMaxLength) {
            return true;
          }
          return value.length <= crnMaxLength;
        }
      };
    },
    errors: function errors() {
      return {
        requiredIf: t("validations.CRN is required"),
        valid: t("validations.CRN is not valid"),
        numbersOnly: t("validations.CRN has to contain only numbers"),
        length: t("validations.CRN has to contain x characters", {
          length: crnLength
        }),
        maxLength: t("validations.CRN can contain a maximum of x characters", {
          length: crnMaxLength
        })
      };
    }
  };
}