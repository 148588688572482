import { registerPageResolver } from '@nsf/core/PageResolver.js'
import { resolveCmsBlogPostById } from '@nsf/cms/repositories/CmsServiceRepository.js'

export default function () {
  registerPageResolver(
    'CMS_SERVICE_BLOG_POST',
    resolveCmsBlogPostById,
    () => import('@nsf/articles/dynamicPages/CmsServiceBlogPost.vue'),
  )
}
