import { maxLength, numeric, required } from "vuelidate/lib/validators/index.js";
export function usePinCodeValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      return {
        required: required,
        numeric: numeric,
        maxLength: maxLength(6)
      };
    },
    errors: function errors() {
      return {
        required: t("validations.required"),
        maxLength: t("validations.maxLength", {
          length: 6
        }),
        numeric: t("validations.CRN has to contain only numbers")
      };
    }
  };
}