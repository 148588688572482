import { field } from '@nsf/core/GraphQL.js'
import { tagPage } from '@nsf/layer-tags/app/gql/fragments/tagPage.ts'

export const tags = field(
  'tags',
  [
    field('name'),
    field('tag_id'),
    field('url_key'),
    field('weight'),
    ...tagPage,
  ],
)
