export default {
  agreementsApiTimeout: 3000,
  consentsApiTimeout: 3000,
  /**
   * dummy object to prevent epic crashes caused by using old config
   * DO NOT USE THIS!!!
   */
  clickAndCollect: {},
  testingEmailSuffixes: [
    '@magento2.drmax.cz',
    '@eshop.drmax.net',
    '@obelix.test',
  ],

  timeApiTimeout: 5000, // in ms
}
