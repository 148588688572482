export default {
  orderModuleConfig: {
    deliveryGroupMapping: {
      pickup_place_cc: [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.new.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.unpaid.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.unpaid.text',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress_payment_complete.label',
          text: '',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.shipping.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.delivered.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled.text',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled_returned.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled_returned.text',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      delivery: [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.new.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.unpaid.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.unpaid.text',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_payment_complete.label',
          text: '',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.shipping.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.delivered.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled.text',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled_returned.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled_returned.text',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      reservation: [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.new.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.inprogress.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.ready_for_pickup.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'picked_up',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.picked_up.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.canceled.text',
          variant: 'error',
          parent: '',
        },
      ],
      pharmacy_purchase: [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.new.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.delivered.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.canceled.text',
          variant: 'error',
          parent: '',
        },
      ],
    },
  },
}
