import dayjs from 'dayjs'

export const BABY_CLUB_DEFAULT = () => ({
  childPregnancyDuration: '',
  childSex: '',
  childBirthDate: '',
  childName: '',
  childStatus: false,
  acceptTerms: false,
})

export function parseValuesFromAgreements(agreements) {
  const response = {
    childSex: '',
    childName: '',
    childPregnancyDuration: '',
    childStatus: false,
    agreement: false,
  }
  const babyClub = agreements.find((item) => item.topic === 'CP_CLUB' && item.channel === 'BABY')
  if (!babyClub || babyClub.value === 'N') {
    return response
  }
  response.agreement = true

  function isPregnant(babyClub) {
    const firstWeek = (babyClub?.items?.find((item) => item.name === 'BIRTH_1_FIRSTWEEK'))
    const dateOfBirth = (babyClub?.items?.find((item) => item.name === 'BIRTH_1_DATEOFBIRTH'))

    if (!firstWeek) {
      response.childStatus = false
      return false
    }

    const mondayOfBirth = dayjs(
      firstWeek.value,
      'YYYY-MM-DD',
    )

    if (mondayOfBirth.isAfter(dayjs())) {
      const thisMonday = dayjs()
        .startOf('isoweek')
      const weeksTillBorn = mondayOfBirth.diff(
        thisMonday,
        'w',
      )
      response.childPregnancyDuration = Math.abs(weeksTillBorn - 40) - 1
      response.childStatus = false
      return true
    }

    if (dateOfBirth) {
      response.childBirthDate = dateOfBirth.value
      response.childStatus = true
    }

    return false
  }

  if (isPregnant(babyClub)) {
    return response
  }

  babyClub.items.forEach((item) => {
    switch (item.name.toLowerCase()) {
      case 'birth_1_children_1_gender':
        response.childSex = item.value === 'f' ? 'female' : 'male'
        break
      case 'birth_1_children_1_name':
        response.childName = item.value
        break
      case 'birth_1_firstweek':
        break
    }
  })
  return response
}

export function buildBabyClub(formData) {
  const babyClub = {
    channel: 'BABY',
    topic: 'CP_CLUB',
    value: 'Y',
    items: [],
  }

  Object.keys(formData.baby)
    .forEach((key) => {
      switch (key) {
        case 'childSex':
          babyClub.items.push({
            name: 'birth_1_children_1_gender',
            value: formData.baby[key] === 'female' ? 'f' : 'm',
          })
          break
        case 'childBirthDate':
          babyClub.items.push({
            name: 'birth_1_dateofbirth',
            value: formData.baby[key],
          })
          babyClub.items.push({
            name: 'birth_1_firstWeek',
            value: false,
          })
          break
        case 'childName':
          babyClub.items.push({
            name: 'birth_1_children_1_name',
            value: formData.baby[key],
          })
          break
        case 'childPregnancyDuration':
          const thisWeek = dayjs()
            .startOf('isoweek')
          const weeksLeft = 40 - formData.baby[key]
          const weekOfBorn = thisWeek.add(
            weeksLeft,
            'w',
          )
          babyClub.items.push({
            name: 'birth_1_firstWeek',
            value: weekOfBorn.format('YYYY-MM-DD'),
          })
          break
      }
    })

  return babyClub
}
