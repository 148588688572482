import { useRuntimeConfig } from "@nsf/use/composables/useRuntimeConfig.js";
import { requiredIf } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  _useAppConfig$validat = _useAppConfig.validations,
  vatIdLength = _useAppConfig$validat.vatIdLength,
  vatIdMinLengthValid = _useAppConfig$validat.vatIdMinLengthValid,
  vatIdMinLength = _useAppConfig$validat.vatIdMinLength,
  vatIdMaxLength = _useAppConfig$validat.vatIdMaxLength,
  vatIdMaxLengthValid = _useAppConfig$validat.vatIdMaxLengthValid,
  vatIdValid = _useAppConfig$validat.vatIdValid,
  vatIdForbiddenWords = _useAppConfig$validat.vatIdForbiddenWords,
  vatIdStartsWith = _useAppConfig$validat.vatIdStartsWith;
var _useRuntimeConfig = useRuntimeConfig(),
  countryCode = _useRuntimeConfig.public.countryCode;
export function useVatIdValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isRequired = _ref.isRequired,
        isRequired = _ref$isRequired === void 0 ? true : _ref$isRequired;
      return {
        requiredIf: requiredIf(function () {
          return isRequired;
        }),
        valid: function valid(value) {
          return value ? vatIdValid(value) : true;
        },
        length: function length(value) {
          return value && vatIdLength ? value.length === vatIdLength : true;
        },
        minLength: function minLength(value) {
          return value && vatIdMinLengthValid ? vatIdMinLengthValid(value) : true;
        },
        maxLength: function maxLength(value) {
          return value && vatIdMaxLengthValid ? vatIdMaxLengthValid(value) : true;
        },
        startsWith: function startsWith(value) {
          return value ? vatIdStartsWith(value) : true;
        },
        doesNotContainForbiddenWords: function doesNotContainForbiddenWords(value) {
          return value ? vatIdForbiddenWords(value) : true;
        }
      };
    },
    errors: function errors() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
          value: ""
        },
        _ref2$value = _ref2.value,
        value = _ref2$value === void 0 ? "" : _ref2$value;
      return {
        requiredIf: t("validations.VAT ID is required"),
        valid: t("validations.VAT ID is not valid"),
        length: vatIdLength ? t("validations.VAT ID has to contain x characters", {
          length: vatIdLength
        }) : vatIdLength,
        minLength: value && vatIdMinLength ? t("validations.VAT ID can contain a minimum of x characters", {
          length: vatIdMinLength
        }) : true,
        maxLength: value && vatIdMaxLength ? t("validations.VAT ID can contain a maximum of x characters", {
          length: vatIdMaxLength
        }) : true,
        startsWith: t("validations.VAT ID has to start with x", [countryCode]),
        doesNotContainForbiddenWords: t("validations.VAT ID contains a forbidden word")
      };
    }
  };
}