import {
  field,
  Query,
  variable,
} from '@nsf/core/GraphQL.js'

const imageFragment = [
  field('alt'),
  field('height'),
  field('url'),
  field('width'),
]

export default () => Query.named('footer')
  .toCheckoutGateway()
  .fields([
    field('certificates', [
      field('image', imageFragment),
      field('link'),
    ]).where('ids', variable('ids', '[Int!]')),
    field('legalText'),
    field('links', [
      field('text'),
      field('url'),
    ]),
    field('logos', [
      field('image', imageFragment),
      field('link'),
    ]),
  ])
