/* eslint-disable import/prefer-default-export */
import { ERROR_MESSAGES } from '@nsf/layer-my-account/utils/errorMessage.js'

export enum ErrorMessages {
  'You are already subscribed to be notified when this product is back in stock. ' = 'catalog.watchdog.you are already subscribed to be notified when this product is back in stock',
  'You are not subscribed to receive stock alerts for this product. ' = 'catalog.watchdog.you are not subscribed to receive stock alerts',
  'You are not subscribed to receive price alerts for this product. ' = 'catalog.watchdog.you are not subscribed to receive price alerts',
  'You are already subscribed to be notified when this product has price' = 'catalog.watchdog.you are already subscribed to be notified',
  'Request failed' = ERROR_MESSAGES.ERROR_SOMETHING_WENT_WRONG,
}
