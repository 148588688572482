import { usePushEvent } from '@nsf/gtm/utils/usePushEvent.js'
import { MISSING_CONFIRMATION, UNAUTHORIZED } from '@nsf/layer-my-account/utils/serverErrorStatus.js'

const pushEventLoginError = () => (
  errorType,
) => {
  let error = ''
  switch (errorType) {
    case MISSING_CONFIRMATION:
      error = 'Unverified account'
      break
    case UNAUTHORIZED:
      error = 'Wrong password or username'
      break
    default:
      error = 'Other'
  }
  const event = {
    event: 'loginError',
    errorType: error,
  }
  usePushEvent(event)
}

export default pushEventLoginError
