import { required, sameAs } from "vuelidate/lib/validators/index.js";
export function usePasswordConfirmationValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      return {
        required: required,
        sameAs: sameAs("password")
      };
    },
    errors: function errors() {
      return {
        required: t("validations.Password confirmation is required"),
        sameAs: t("validations.Passwords must match")
      };
    }
  };
}