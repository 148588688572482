export const PICKUP_PLACE = 'pickup-place'
export const POST_OFFICE = 'post-office'
export const PHARMACY = 'pharmacy'
export const PACKETA = 'packeta'
export const DRMAX_BOX = 'drmax_box'
export const PACZKOMAT = 'paczkomat'
export const INPOST = 'inpost'
export const INPOST_ITA_BOX = 'inpost_ita_box'
export const INPOST_ITA_POK = 'inpost_ita_pok'

export const COMMON_MAP_METHOD_CODE = 'common_map_proxy'

export const RESERVATION = 'reservation'
export const ORDER = 'order'
export const MIXED = 'mixed'

export const PAYU_GATEWAY = 'payu_gateway'
export const STRIPE_GATEWAY = 'stripe_gateway'

export const STOCK_STATUSES = {
  UNKNOWN: 'UNKNOWN',
  LOADING: 'LOADING',
  IN_STOCK: 'IN_STOCK',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
}

export const BOX_STATUSES = {
  NOT_IN_STOCK: 1,
  UNFIT_FOR_BOX: 2,
}

export const PHARMACY_STATUSES = {
  NOT_IN_STOCK: 1,
}

export const EXCEPTIONS = {
  QUANTITY_UNAVAILABLE: 1,
}

/**
 * Update data marked by path.
 *
 * @example
 * ```
 * setStateByPath(state, { path: 'payment.paymentMethods', value: paymentMethods })
 * ```
 *
 * @param state Vuex state
 * @param path Comma-separated path, e.g. `a.b.c`
 * @param value New value to be set
 */
export const setStateByPath = (state, { path, value }) => {
  let i = 0
  let target = state
  const splitPath = path.split('.')
  while (i < splitPath.length - 1) {
    target = target[splitPath[i]]
    i = i + 1
  }
  target[splitPath[i]] = value
}

export const getItems = (itemGroups = []) => ({
  items: itemGroups
    .flatMap((group) => group.items)
    .filter((group) => !!group),
})

export const getSalesRuleItems = (itemGroups = []) => {
  // to get only GIFT or ITEM types as items/products
  const filteredRules = itemGroups
    .flatMap((group) => group.rules)
    .filter((group) => group.type !== 'GENERIC')

  return {
    salesRuleItems: filteredRules
      ? filteredRules.flatMap((rule) => (
        // add label and type into items
        rule.items.map((item) => ({
          ...item,
          label: rule.label,
          type: rule.type,
        }))
      ))
      : [],
  }
}

export const getCurrentProducts = (items, salesRuleItems, config) => {
  let products = []

  if (config.marketplaceEnabled) {
    products = items.filter((item) => !item.product.drmaxIsMarketplaceProduct).map(({
      brand,
      product: { sku },
      quantity,
    }) => ({
      brand,
      sku,
      quantity,
    }))
  } else {
    products = items.map(({
      brand,
      product: { sku },
      quantity,
    }) => ({
      brand,
      sku,
      quantity,
    }))
  }

  const salesRuleProducts = salesRuleItems.map(({
    brand,
    product: { sku },
    quantity,
  }) => ({
    brand,
    sku,
    quantity,
  }))

  return { products, salesRuleProducts }
}

export const getAllProducts = (products, salesRuleProducts) => {
  const allProducts = [...products]

  for (const salesRuleProduct of salesRuleProducts) {
    const index = allProducts.findIndex(({ sku }) => sku === salesRuleProduct.sku)

    // Immutably sum the quantity of the same products
    if (index >= 0) {
      const quantity = allProducts[index].quantity + salesRuleProduct.quantity
      allProducts[index] = { ...allProducts[index], quantity }
    // Add product if there is no match
    } else {
      allProducts.push(salesRuleProduct)
    }
  }

  return { allProducts }
}

export const getProductRowsWithPrices = (items, salesRuleItems) => {
  const products = items.map(({
    id,
    product: { sku },
    quantity,
    prices: { rowTotalWithDiscountIncludingTax: { value } },
  }) => ({
    id, sku, quantity, price: value,
  }))

  const salesRuleProducts = salesRuleItems.map(({
    id,
    product: { sku },
    quantity,
    prices: { rowTotalWithDiscountIncludingTax: { value } },
  }) => ({
    id, sku, quantity, price: value,
  }))

  return [...products, ...salesRuleProducts]
}

export const getShippingMethods = (shippingMethodsSimple, pickupIds, config) => shippingMethodsSimple.map((shippingMethod) => {
  const simpleDeliveryTime = shippingMethod.simpleDeliveryTime ?? null
  const simpleDeliveryTimeInfo = shippingMethod.simpleDeliveryTimeInfo ?? null
  const courierId = shippingMethod.courierId ?? null
  const drmaxPickupIds = pickupIds[shippingMethod.carrierCode]
  const priceExclTax = shippingMethod.priceExclTax ?? null
  const priceInclTax = shippingMethod.priceInclTax ?? null

  if (shippingMethod.carrierCode === config.pharmacyCollectCarrierCode) {
    return {
      ...shippingMethod,
      courierId,
      drmaxPickupIds,
      pickupPlaceTypes: ['PHARMACY'],
      simpleDeliveryTime,
      simpleDeliveryTimeInfo,
      priceExclTax,
      priceInclTax,
      pins: [
        {
          type: 'PHARMACY',
          pins: [
            {
              path: '/img/delivery/cac.svg',
              status: 'icon',
            },
            {
              path: '/img/map/pin-pharmacy-enabled.svg',
              status: 'enabled',
            },
            {
              path: '/img/map/pin-pharmacy-disabled.svg',
              status: 'disabled',
            },
            {
              path: '/img/map/pin-pharmacy-selected.svg',
              status: 'selected',
            },
            {
              status: 'unknown',
            },
          ],
        },
      ],
    }
  }
  if (shippingMethod.carrierCode === config.packetaCollectCarrierCode) {
    return {
      ...shippingMethod,
      courierId,
      drmaxPickupIds,
      pickupPlaceTypes: ['PACKETA'],
      simpleDeliveryTime,
      simpleDeliveryTimeInfo,
      priceExclTax,
      priceInclTax,
      pins: [
        {
          type: 'PACKETA',
          pins: [
            {
              path: '/img/delivery/packeta.svg',
              status: 'icon',
            },
            {
              path: '/img/map/pin-packeta-enabled.svg',
              status: 'enabled',
            },
            {
              path: '/img/map/pin-packeta-disabled.svg',
              status: 'disabled',
            },
            {
              path: '/img/map/pin-packeta-selected.svg',
              status: 'selected',
            },
            {
              status: 'unknown',
            },
          ],
        },
      ],
    }
  }
  return {
    ...shippingMethod,
    courierId,
    drmaxPickupIds,
    simpleDeliveryTime,
    simpleDeliveryTimeInfo,
  }
}).filter(({ carrierCode }) => !config.forbiddenCarrierCodes.includes(carrierCode))

export const getCarrierCodeMap = (shippingMethods) => {
  const carrierCodeMap = {}

  for (const { carrierCode, integrationId } of shippingMethods) {
    if (carrierCodeMap[carrierCode]) {
      carrierCodeMap[carrierCode] = [...carrierCodeMap[carrierCode], ...integrationId]
    } else {
      carrierCodeMap[carrierCode] = integrationId
    }
  }

  return carrierCodeMap
}
