import { isProd } from '@nsf/core/Utils.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { delCookie, getCookie, setCookie } from '@nsf/utils/CookieUtils.js'
import jwtDecode from 'jwt-decode'

const { public: { myAccountStartTimeDisabled, myAccountEndTimeDisabled } } = useRuntimeConfig()
export const AUTH_TOKEN_KEY = isProd() ? 'liveToken' : 'stageToken'
export const AUTH_REFRESH_TOKEN_KEY = isProd() ? 'liveRefreshToken' : 'stageRefreshToken'

const setCookieToken = (key, value, decodedToken) => {
  const tokenExpires = new Date(decodedToken.exp * 1000).toUTCString()
  setCookie(key, value, { expires: tokenExpires })
}

export const decodeAndSetTokens = ({ accessToken, refreshToken }) => {
  if (!accessToken) {
    return
  }

  setCookieToken(AUTH_TOKEN_KEY, accessToken, jwtDecode(accessToken))

  if (refreshToken) {
    setCookieToken(AUTH_REFRESH_TOKEN_KEY, refreshToken, jwtDecode(refreshToken))
  }
}

export const removeTokens = () => {
  delCookie(AUTH_TOKEN_KEY)
  delCookie(AUTH_REFRESH_TOKEN_KEY)
}

export const getToken = () => getCookie(AUTH_TOKEN_KEY)
export const getRefreshToken = () => getCookie(AUTH_REFRESH_TOKEN_KEY)
export const getAuthorization = (token = null) => `Bearer ${token || getToken()}`
export const hasAnyToken = () => !!getToken() || !!getRefreshToken()

export const getTokenFromReqCookies = (req) => {
  const cookieHeader = req?.headers?.cookie || null

  if (!cookieHeader) {
    return {}
  }

  const token = isProd() ? 'liveToken' : 'stageToken'

  return Object.fromEntries(
    cookieHeader.split(';').map((cookie) => {
      const [name, ...rest] = cookie.split('=')
      return [name?.trim(), decodeURIComponent(rest.join('=').trim())]
    }),
  )?.[token]
}

export const isMyAccountEnabledOnTime = () => {
  if (!myAccountStartTimeDisabled || !myAccountEndTimeDisabled) {
    return true
  }
  const START_TIME = new Date(myAccountStartTimeDisabled)
  const END_TIME = new Date(myAccountEndTimeDisabled)

  return !(new Date() <= END_TIME && new Date() >= START_TIME)
}

export const isMyAccountEnabled = () => isMyAccountEnabledOnTime()
