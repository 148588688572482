import { mapDataToModel } from '@nsf/core/Mapper.js'
import { extract } from '@nsf/core/Utils.js'

/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
export const mapDataToTag = (data: object, translations = {}) => {
  const model = mapDataToModel(data, {
    /* eslint-disable camelcase */
    tag_id: ['id', Number],
    weight: Number,
    name: String,
    full_content: ['content', JSON.parse],
    meta_title: String,
    meta_keywords: String,
    meta_description: String,
    url_key: String,
    page_is_enabled: Boolean,
    ...translations,
  })
  if (!model.metaTitle) {
    model.metaTitle = model.name
  }
  model.seo = extract(model, 'metaTitle', 'metaDescription', 'metaKeywords')
  return model
}
/* eslint-enable camelcase */
/* eslint-enable import/prefer-default-export */
