import { mapDataToModel } from '@nsf/core/Mapper.js'
import { extract } from '@nsf/core/Utils.js'
import { mapDataToArticle } from './ArticleMapper.js'

export const mapDataToAuthor = (
  data, translations = {}, seoSideEffects = false,
) => {
  const model = mapDataToModel(
    data,
    {
      author_id: [
        'id',
        Number,
      ],
      posts: (data) => ({
        items: mapDataToArticle(data.items),
        count: data.count,
      }),
      meta_tags: [
        'metaKeywords',
        String,
      ],
      ...translations,
    },
  )

  // seo side effects used only for author page
  if (model.id && seoSideEffects) {
    model.seo = extract(
      model,
      'metaTitle',
      'metaDescription',
      'metaKeywords',
    )
  }

  return model
}

export const mapAuthorFromCmsService = (author) => {
  const { attributes } = author

  let photo = null
  try {
    photo = JSON.parse(author.attributes?.photo || '{}')
  } catch (e) {

  }

  const posts = author.posts ?? {
    items: [],
    count: author.postsCount || 0,
  }

  return {
    id: author.entityId,
    name: author.name,
    photo: photo?.src,
    description: attributes?.description,
    urlKey: attributes?.urlKey,
    posts,
    source: 'cms-service',
  }
}
