import { maxLength, requiredIf } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  _useAppConfig$validat = _useAppConfig.validations,
  cityMaxLength = _useAppConfig$validat.cityMaxLength,
  cityAllowedCharacters = _useAppConfig$validat.cityAllowedCharacters,
  cityContainsLetter = _useAppConfig$validat.cityContainsLetter;
export function useCityValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isRequired = _ref.isRequired,
        isRequired = _ref$isRequired === void 0 ? true : _ref$isRequired;
      return {
        requiredIf: requiredIf(function () {
          return isRequired;
        }),
        maxLength: maxLength(cityMaxLength),
        allowedCharacters: cityAllowedCharacters,
        cityContainsLetter: cityContainsLetter
      };
    },
    errors: function errors() {
      return {
        requiredIf: t("validations.City is required"),
        maxLength: t("validations.City can contain a maximum of x characters", {
          length: cityMaxLength
        }),
        allowedCharacters: t("validations.City contains disallowed characters"),
        cityContainsLetter: t("validations.City must contain at least one letter")
      };
    }
  };
}