import { mapDataToModel } from '@nsf/core/Mapper.js'
import { isPhoneCode } from '@nsf/core/Utils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'

const {
  checkout: {
    features: {
      phoneCodeEnabled,
      phoneSlicingEnabled,
    },
  },
  rootConfig: {
    global: {
      phoneCode: defaultPhoneCode,
    },
  },
} = useAppConfig()

const mapDataToBillingAddressContact = (data, translations = {}) => {
  const { firstname, lastname, telephone } = mapDataToModel(data, translations)

  let phoneCode
  let phoneNumber = telephone

  if (telephone && phoneSlicingEnabled) {
    let index

    if (phoneNumber.startsWith(defaultPhoneCode)) {
      index = defaultPhoneCode.length
    } else if (telephone.length === 13) {
      index = 4
    } else if (telephone.length === 12) {
      index = 3
    }

    phoneCode = telephone.substring(0, index)
    phoneNumber = telephone.substring(index)
  }

  return {
    firstname,
    lastname,
    phoneCode: !phoneCodeEnabled && isPhoneCode(phoneCode) ? phoneCode : defaultPhoneCode,
    phoneNumber,
  }
}

export default mapDataToBillingAddressContact
