import "core-js/modules/es.regexp.exec.js";
import { required } from "vuelidate/lib/validators/index.js";
export function useCompetitionCoupon() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      return {
        required: required,
        couponValid: function couponValid(value) {
          return /^[\S\d]{12}$/gm.test(value);
        }
      };
    },
    errors: function errors() {
      return {
        required: t("validations.Coupon is required"),
        couponValid: t("validations.Coupon is not valid")
      };
    }
  };
}