import { getPlacementBanners } from '@nsf/layer-product-relationship-loader-placement/app/utils/repositories/PlacementRepository.ts'
import type { PageTypeValues, PlacementOptions } from '@nsf/layer-product-relationship-loader-placement/types/product-relationship-loader-placement.ts'

export default async function (pageType: PageTypeValues | null = null) {
  const userId = ''
  const config: PlacementOptions = this?.config ?? {
    limit: 1,
    pageType,
  }
  return await getPlacementBanners('search-suggest-banner', userId, config, this?.ctx)
}
