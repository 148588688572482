import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import { maxLength, required } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  _useAppConfig$validat = _useAppConfig.validations,
  lastNameMaxLength = _useAppConfig$validat.lastNameMaxLength,
  forbiddenWords = _useAppConfig$validat.forbiddenWords,
  firstLastNameAllowedCharacters = _useAppConfig$validat.firstLastNameAllowedCharacters;
export function useLastNameValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isForbiddenWords = _ref.isForbiddenWords,
        isForbiddenWords = _ref$isForbiddenWords === void 0 ? false : _ref$isForbiddenWords;
      return {
        required: required,
        maxLength: maxLength(lastNameMaxLength),
        allowedCharacters: firstLastNameAllowedCharacters,
        doesNotContainForbiddenWords: function doesNotContainForbiddenWords(value) {
          return isForbiddenWords ? !forbiddenWords.some(function (word) {
            return value.toLowerCase().includes(word.toLowerCase());
          }) : true;
        }
      };
    },
    errors: function errors() {
      return {
        required: t("validations.Last name is required"),
        maxLength: t("validations.Last name can contain a maximum of x characters", {
          length: lastNameMaxLength
        }),
        allowedCharacters: t("validations.Last name contains disallowed character"),
        doesNotContainForbiddenWords: t("validations.Last name contains a forbidden word")
      };
    }
  };
}