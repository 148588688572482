import logger from '@nsf/layer-my-account/logger.ts'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import createCustomerServiceClient from '../clients/CustomerServiceClient.js'
import {
  mapDataToFavoritePaymentMethod,
  mapDataToFavoritePaymentMethods,
  mapDataToFavoriteShippingMethod,
  mapDataToFavoriteShippingMethods,
  mapFavoritePaymentMethodToData,
  mapFavoriteShippingMethodToData,
} from '../mappers/FavoriteMethodsMapper.js'
import { withAuth } from '../utils/authDecorator.js'

const {
  public: { customerServiceUrlV2 },
} = useRuntimeConfig()

export const getFavoritePaymentMethods = async () => {
  try {
    const { ok, data } = await createCustomerServiceClient(customerServiceUrlV2).get(
      '/favourite/payment-methods?active=true',
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
      paymentMethods: mapDataToFavoritePaymentMethods(data),
    }
  } catch (e) {
    logger.error('getFavoritePaymentMethods failed: %s', e.message)
    return {
      ok: false,
      paymentMethods: [],
    }
  }
}

export const getFavoriteShippingMethods = async () => {
  try {
    const { ok, data } = await createCustomerServiceClient(customerServiceUrlV2).get(
      '/favourite/shipping-methods?active=true',
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
      shippingMethods: mapDataToFavoriteShippingMethods(data),
    }
  } catch (e) {
    logger.error('getFavoriteShippingMethods failed: %s', e.message)
    return {
      ok: false,
      shippingMethods: [],
    }
  }
}

export const setFavoritePaymentMethod = async (payload) => {
  try {
    const { ok } = await withAuth(createCustomerServiceClient(customerServiceUrlV2).put)(
      `/favourite/payment-method`,
      mapFavoritePaymentMethodToData(payload),
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
    }
  } catch (e) {
    logger.error('setFavoritePaymentMethod failed: %s', e.message)

    return {
      ok: false,
    }
  }
}

export const setFavoriteShippingMethod = async (payload) => {
  try {
    const { ok } = await withAuth(createCustomerServiceClient(customerServiceUrlV2).put)(
      `/favourite/shipping-method`,
      mapFavoriteShippingMethodToData(payload),
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
    }
  } catch (e) {
    logger.error('setFavoriteShippingMethod failed: %s', e.message)

    return {
      ok: false,
    }
  }
}

export const getFavoritePaymentMethod = async () => {
  try {
    const { ok, data } = await withAuth(createCustomerServiceClient(customerServiceUrlV2).get)(
      `/favourite/payment-method`,
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
      paymentMethod: mapDataToFavoritePaymentMethod(data?.payment),
    }
  } catch (e) {
    logger.error('getFavoritePaymentMethod failed: %s', e.message)

    return {
      ok: false,
      paymentMethod: null,
    }
  }
}

export const getFavoriteShippingMethod = async () => {
  try {
    const { ok, data } = await withAuth(createCustomerServiceClient(customerServiceUrlV2).get)(
    `/favourite/shipping-method`,
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
      shippingMethod: mapDataToFavoriteShippingMethod(data?.shipping),
    }
  } catch (e) {
    logger.error('getFavoriteShippingMethod failed: %s', e.message)

    return {
      ok: false,
      shippingMethod: null,
    }
  }
}
