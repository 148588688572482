import "core-js/modules/es.regexp.exec.js";
import { maxLength, required } from "vuelidate/lib/validators/index.js";
var _useAppConfig = useAppConfig(),
  emailMaxLength = _useAppConfig.validations.emailMaxLength;
export var EMAIL_VALIDATION_REGEX = /^(?=.{1,64}@.{4,255}$)([A-Za-z0-9_+\-.]{1,64})@([a-zA-Z0-9-]{1,63}\.)+([A-Za-z]{2,63})$/;
export var RESTRICTED_DOMAIN_EMAIL_REGEX = /@mapn(\.|$)/;
export function useEmailValidation() {
  var _useI18n = useI18n(),
    t = _useI18n.t;
  return {
    validate: function validate() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$isRestrictedDoma = _ref.isRestrictedDomainEnabled,
        isRestrictedDomainEnabled = _ref$isRestrictedDoma === void 0 ? false : _ref$isRestrictedDoma;
      return {
        required: required,
        email: function email(value) {
          return EMAIL_VALIDATION_REGEX.test(value);
        },
        maxLength: maxLength(emailMaxLength),
        restrictedDomain: function restrictedDomain(value) {
          return isRestrictedDomainEnabled ? !RESTRICTED_DOMAIN_EMAIL_REGEX.test(value) : true;
        }
      };
    },
    errors: function errors() {
      return {
        required: t("validations.Email is required"),
        email: t("validations.Email is not in correct format"),
        maxLength: t("validations.Email can contain a maximum of x characters", {
          length: emailMaxLength
        }),
        restrictedDomain: t("validations.Sorry you cannot register the loyalty card with this email address")
      };
    }
  };
}