import {
  field, Mutation, variable,
} from '@nsf/core/GraphQL.js'
import {
  REQUIRED_INT,
  REQUIRED_STRING,
} from '@nsf/core/consts/GraphQLTypes.js'

export default () => Mutation.named('stockAlertSubscribe')
  .where(
    'input',
    {
      email: variable(
        'email',
        REQUIRED_STRING,
      ),
      customer_name: variable(
        'name',
        REQUIRED_STRING,
      ),
      product_id: variable(
        'productId',
        REQUIRED_INT,
      ),
    },
  )
  .fields([
    field('guest'),
    field('client_uid'),
  ])
