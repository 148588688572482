import { field } from '@nsf/core/GraphQL.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import cartShippingAddressesAvailableShippingMethods from './cartShippingAddressesAvailableShippingMethods.js'
import cartShippingAddressesSelectedShippingMethod from './cartShippingAddressesSelectedShippingMethod.js'
import cartShippingAddressesAppliedShippingRestrictions from './cartShippingAddressesAppliedShippingRestrictions.js'

const {
  checkout: {
    features: {
      streetNumberToMagentoEnabled,
    },
  },
  rootConfig: {
    global: {
      streetNumberEnabled,
    },
  },
} = useAppConfig()

export default field('shipping_addresses', [
  field('firstname'),
  field('lastname'),
  field('street'),
  ...(streetNumberToMagentoEnabled && streetNumberEnabled ? [field('streetNumber: drmax_sk_house_number')] : []),
  field('postcode'),
  field('city'),
  field('region', [
    field('code'),
  ]),
  field('country', [
    field('code'),
  ]),
  field('telephone'),
  cartShippingAddressesAppliedShippingRestrictions,
  cartShippingAddressesAvailableShippingMethods,
  cartShippingAddressesSelectedShippingMethod,
  field('is_common_map_enabled'),
  field('common_map_icon'),
])
